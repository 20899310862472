import { createContext } from 'react';
import {
  TClosedTickets,
  TOpenTickets,
  TSupportContext,
  TSupportContextData,
  TFetchTicketStatus,
  TSupportTicketNewWithPlantDetails,
} from '@/types/support';

export const SupportContext = createContext<TSupportContext>({
  supportDataState: {} as TSupportContextData,
  onGetSupportSuccess: (data: TSupportTicketNewWithPlantDetails[]) => {},
  errorWhileFetchingData: false,
  loadingData: false,
  appendTicketOnSuccess: (data: TSupportTicketNewWithPlantDetails) => {},
  openTicketsList: {} as TOpenTickets,
  closedTicketsList: {} as TClosedTickets,
  appendOpenTickets: (data: TSupportTicketNewWithPlantDetails[]) => {},
  appendClosedTickets: (data: TSupportTicketNewWithPlantDetails[]) => {},
  triggerFetchOpenOrClosedTickets: (status: TFetchTicketStatus) => {},
});
