import { useTranslation } from 'react-i18next';

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from '@mui/material';
import { useField, useFormikContext } from 'formik';

import { SimpleError } from '@/components/formik/error/SimpleError';

import type { Override, TOption } from '@/types/common';

type TFormikSelectProps<PValue> = Override<
  SelectProps,
  {
    name: string;
    label: string;
    options: TOption<PValue>[];
    toIdentifier?: (value: PValue) => string;
    'data-testid'?: string;
    disableSelect?: boolean;
    defaultValue?: string;
  }
>;

export const FormikSelect = <PValue extends unknown>({
  name,
  options,
  label: labelKey,
  'data-testid': testId,
  toIdentifier = (value: PValue) => value as string,
  disableSelect = false,
  defaultValue = '',
  ...props
}: TFormikSelectProps<PValue>) => {
  const [field, meta, helpers] = useField<PValue>(name);
  const { submitCount } = useFormikContext();
  const { t } = useTranslation();

  const label = t(labelKey);
  const labelId = `label-for-${name}`;
  const errorsShown = Boolean(meta.error) && submitCount > 0;

  const renderValue = (value: unknown) => {
    const id = toIdentifier(value as PValue);
    const selected = options.find((opt) => toIdentifier(opt.value) === id);

    return <>{selected ? t(selected.label) : ''}</>;
  };

  const renderOption = (option: TOption<PValue>) => {
    const id = toIdentifier(option.value);

    return (
      <MenuItem key={id} value={id} disabled={option.disabled}>
        {t(option.label)}
      </MenuItem>
    );
  };

  const onChange = ({ target }: SelectChangeEvent<unknown>) => {
    const selected = options.find((opt) => toIdentifier(opt.value) === target.value);

    if (selected) {
      helpers.setValue(selected?.value);
    }
  };

  return (
    <FormControl
      style={{
        marginTop: 'unset',
      }}
    >
      <InputLabel variant="filled" id={labelId}>
        {label}
      </InputLabel>
      <Select
        labelId={labelId}
        label={label}
        value={field.value ?? ''}
        renderValue={renderValue}
        onChange={onChange}
        error={errorsShown}
        inputProps={{
          'data-testid': testId,
        }}
        disabled={disableSelect}
        defaultValue={defaultValue}
        {...props}
      >
        {options.map(renderOption)}
      </Select>

      <FormHelperText component="div">
        <SimpleError error={meta.error} errorsShown={errorsShown} label={label} />
      </FormHelperText>
    </FormControl>
  );
};
