import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FilledInput,
  FormControl,
  FormHelperText,
  Input as MuiInput,
  InputLabel,
  InputProps,
  OutlinedInput,
  TextFieldProps,
} from '@mui/material';
import { useField, useFormikContext } from 'formik';

import type { Override } from '@/types/common';

import { SimpleError } from './error/SimpleError';
import type { TErrorProps } from './error/types';

type TInputVariants = TextFieldProps['variant'];

export type TFormikTextProps<PError> = Override<
  InputProps,
  {
    name: string;
    label: string;
    margin?: InputProps['margin'];
    'data-testid'?: string;
    variant?: TInputVariants;
    labelVariant?: TInputVariants;
    HelperText?: (props: TErrorProps<PError>) => JSX.Element | null;
    triggerOnBlur?: (updatedValue: string) => void;
    disableInput?: boolean;
    defaultValue?: string;
  }
>;

const INPUT_TYPE = {
  outlined: OutlinedInput,
  standard: MuiInput,
  filled: FilledInput,
};

export const FormikText = <PError extends unknown>({
  name,
  margin,
  label: labelKey,
  'data-testid': testId,
  variant = 'standard',
  labelVariant = 'standard',
  HelperText = SimpleError as (props: TErrorProps<PError>) => JSX.Element,
  triggerOnBlur,
  defaultValue = '',
  disableInput = false,
  ...props
}: TFormikTextProps<PError>) => {
  const [field, meta, metaHelpers] = useField(name);
  const inputRef = useRef<HTMLInputElement>(null);
  const { submitCount } = useFormikContext();
  const { t } = useTranslation();

  const label = t(labelKey);
  const errorsShown = Boolean(meta.error) && submitCount > 0;
  const Input = INPUT_TYPE[variant];

  useEffect(() => {
    const handleBlur = () => {
      if (triggerOnBlur && field.value) {
        triggerOnBlur(field.value);
      }
    };

    inputRef.current?.addEventListener('blur', handleBlur);

    return () => {
      inputRef.current?.removeEventListener('blur', handleBlur);
    };
  }, [field.value, triggerOnBlur]);

  useEffect(() => {
    if (defaultValue) {
      metaHelpers.setValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <FormControl margin={margin}>
      <InputLabel variant={labelVariant}>{label}</InputLabel>
      <Input
        name={name}
        label={name}
        inputRef={inputRef}
        value={field.value ?? ''}
        onChange={field.onChange}
        onBlur={field.onBlur}
        error={errorsShown}
        inputProps={{
          'data-testid': testId,
        }}
        {...props}
        disabled={disableInput}
      />

      <FormHelperText component="div">
        <HelperText
          error={meta.error as PError | undefined}
          errorsShown={errorsShown}
          label={label}
        />
      </FormHelperText>
    </FormControl>
  );
};
