import { typedStyles } from '@/utils/styleUtils';

export const styles = typedStyles({
  tabs: ({ palette }) => ({
    borderBottom: `1px solid ${palette.divider}`,
    '& .MuiTabs-flexContainer': {
      justifyContent: 'center',
    },
  }),
});
