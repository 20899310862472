import { useEffect } from 'react';
import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useTranslation } from 'react-i18next';

import { SimpleError } from '@/components/formik/error/SimpleError';

type TFormikDateProps = {
  name: string;
  label: string;
  'data-testid'?: string;
  defaultValue?: Date | null;
};

export const FormikDate = ({
  name,
  label: labelKey,
  'data-testid': testId,
  defaultValue,
  ...props
}: TFormikDateProps) => {
  const [field, meta, helpers] = useField<Date | null>(name);
  const { submitCount } = useFormikContext();
  const { t } = useTranslation();

  const label = t(labelKey);
  const errorsShown = Boolean(meta.error) && submitCount > 0;

  useEffect(() => {
    if (defaultValue && !field.value) {
      helpers.setValue(defaultValue);
    }
  }, [defaultValue, field.value, helpers]);

  const onChange = (date: Date | null) => {
    helpers.setValue(date);
  };

  return (
    <FormControl>
      <DatePicker
        label={label}
        format="YYYY-MM-DD"
        value={field.value ?? null}
        onChange={onChange}
      />
      <FormHelperText component="div">
        <SimpleError error={meta.error} errorsShown={errorsShown} label={label} />
      </FormHelperText>
    </FormControl>
  );
};
