import { useMemo } from 'react';

import { Box } from '@mui/material';
import { Form, Formik, FormikConfig } from 'formik';
import type { InferType, BaseSchema } from 'yup';

import type { DeepPartial, Override, TSxStyles } from '@/types/common';

type TFormikFormProps<PSchema extends BaseSchema, PValues = InferType<PSchema>> = Override<
  FormikConfig<PValues>,
  {
    validationSchema: PSchema;
    initialValues?: DeepPartial<PValues>;
    sx?: TSxStyles;
  }
>;

const getErrors = <PSchema extends BaseSchema>(schema: PSchema, values: InferType<PSchema>) => {
  try {
    schema.validateSync(values);
  } catch (error) {
    return { [error.path]: error.message };
  }

  return {};
};

export const FormikForm = <PSchema extends BaseSchema>({
  validationSchema,
  initialValues,
  children,
  sx,
  ...props
}: TFormikFormProps<PSchema>) => {
  const initial = useMemo(
    () => ({
      values: validationSchema.cast(initialValues ?? {}),
      errors: getErrors(validationSchema, initialValues ?? {}),
    }),
    [validationSchema, initialValues],
  );

  return (
    <Formik
      initialValues={initial.values}
      initialErrors={initial.errors}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
      {...props}
    >
      <Box component={Form} sx={sx}>
        {children}
      </Box>
    </Formik>
  );
};
