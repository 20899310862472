import { typedStyles } from '@/utils/styleUtils';

export const styles = typedStyles({
  content: {
    flex: 1,
    minHeight: 0,
    flexDirection: 'column',
    position: 'relative',
    maxHeight: 600,
    borderBottomLeftRadius: 'inherit',
    borderBottomRightRadius: 'inherit',
  },

  grid: {
    flex: 1,
    minHeight: 0,
    marginTop: '-1px',
    overflow: 'auto',
  },
});
