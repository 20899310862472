import { SyntheticEvent } from 'react';

import { Tab, Tabs as MuiTabs } from '@mui/material';

import { styles } from './Faq.styles';
import { TFaqTab, TTabVariant } from './types';

type TTabsProps = {
  tabs: TFaqTab[];
  selectedTab: string;
  setSelectedTab: (value: TTabVariant) => void;
};

export const Tabs = ({ tabs, selectedTab, setSelectedTab }: TTabsProps) => {
  const handleChange = (_: SyntheticEvent<Element, Event>, value: TTabVariant) => {
    setSelectedTab(value);
  };

  return (
    <MuiTabs sx={styles.tabs} value={selectedTab} onChange={handleChange}>
      {tabs.map(({ tabTitle, key }) => (
        <Tab sx={{ textTransform: 'none' }} key={key} value={key} label={tabTitle} />
      ))}
    </MuiTabs>
  );
};
