import { typedStyles } from '@/utils/styleUtils';

export const styles = typedStyles({
  modal: {
    width: 'min(80%, 480px)',
    height: 'min(80%, 626px)',
  },

  header: {
    position: 'relative',
    marginBottom: 3,
  },

  form: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    overflow: 'auto',
    paddingTop: 8,
  },

  fields: {
    flex: 1,
    paddingX: 6,
    paddingBottom: 6,
  },

  infoBox: {
    gap: 1.25,
    padding: 2.5,
    flexDirection: 'column',
  },
});
