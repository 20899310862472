import { useTranslation } from 'react-i18next';

import { Box, Button, Typography } from '@mui/material';

import { AUTH_ROUTES } from '@/config/routes';

import { FormikForm } from '@/components/formik/FormikForm';
import { FormikSubmit } from '@/components/formik/FormikSubmit';

import type { TOnSubmit } from '@/types/formTypes';

import { changePasswordSchema } from '@/validation/settings';

import { CreatePasswordErrors } from '../createPasswordErrors/CreatePasswordErrors';
import { ActionAndError } from '../formik/error/ActionAndError';
import { FormikPassword } from '../formik/FormikPassword';
import { GenericModalFooter } from '../modal/GenericModalFooter';
import { styles } from './ChangePassword.styles';
import { TChangePasswordFormData } from './types';

type TChangePasswordForm = {
  handleSubmit: TOnSubmit<TChangePasswordFormData>;
  closeModal: () => void;
};
export const ChangePasswordForm = ({ handleSubmit, closeModal }: TChangePasswordForm) => {
  const { t } = useTranslation();

  return (
    <FormikForm validationSchema={changePasswordSchema} onSubmit={handleSubmit} sx={styles.form}>
      <Box sx={styles.fields}>
        <Box>
          <Box sx={styles.header}>
            <Typography variant="h5">{t('changePassword.title')}</Typography>
          </Box>

          <FormikPassword<string>
            name="currentPassword"
            label="changePassword.currentPassword"
            // HelperText={(errorProps) => (
            //   <ActionAndError
            //     sx={{
            //       '&': {
            //         flexDirection: 'row-reverse',
            //         justifyContent: 'space-between',
            //         '& .MuiTypography-root': {
            //           color: 'text.secondary',
            //         },
            //         '& .MuiButton-root': {
            //           marginRight: 0,
            //         },
            //       },
            //     }}
            //     data-testid="forgotPassword"
            //     action={t('signIn.forgotPassword')}
            //     onClick={() => {
            //       window.open(AUTH_ROUTES.forgotPassword, '_blank');
            //     }}
            //     withIcon
            //     {...errorProps}
            //   />
            // )}
          />

          <FormikPassword
            name="newPassword"
            label="changePassword.newPassword"
            HelperText={() => null}
          />

          <FormikPassword
            name="confirmPassword"
            label="changePassword.confirmNewPassword"
            HelperText={CreatePasswordErrors}
          />
        </Box>
      </Box>

      <GenericModalFooter>
        <Button variant="outlined" onClick={closeModal}>
          {t('common.cancel')}
        </Button>

        <FormikSubmit>{t('common.change')}</FormikSubmit>
      </GenericModalFooter>
    </FormikForm>
  );
};
