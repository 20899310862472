import { Grid } from '@mui/material';

import { FormikAutocomplete } from '@/components/formik/FormikAutocomplete';
import { FormikText } from '@/components/formik/FormikText';

import { STATE_ABBREVIATIONS } from '@/mocks/states';

export const BusinessAddressFields = () => {
  return (
    <div>
      <FormikText
        name="streetAddress"
        label="changeBusinessAddress.streetAddress"
        data-testid="streetAddress"
      />

      <FormikText
        name="secondaryAddress"
        label="changeBusinessAddress.secondaryAddress"
        data-testid="secondaryAddress"
      />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormikText name="city" label="changeBusinessAddress.city" data-testid="city" />
        </Grid>

        <Grid item xs={6}>
          <FormikAutocomplete
            name="state"
            options={STATE_ABBREVIATIONS}
            label="changeBusinessAddress.state"
            data-testid="state"
          />
        </Grid>
      </Grid>

      <FormikText name="zip" label="changeBusinessAddress.zip" data-testid="zip" />
    </div>
  );
};
