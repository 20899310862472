import * as Yup from 'yup';

import { requiredString } from './common';

export const createTicketSchema = Yup.object({
  plantId: requiredString,
  productName: requiredString,
  unitSerialNumber: requiredString,
  subject: requiredString,
  issueDescription: requiredString,
  country: requiredString,
  language: requiredString,
}).required();
