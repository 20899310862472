import { useContext, useState, useEffect } from 'react';
import { DashboardContext } from './DashboardContext';
import { TProviderProps } from '../types';
import { TDashboardResponse } from '@/types/dashboard';
import { getDashboardData } from '@/services/dashboard';
import { useAuthData } from '@/contexts/AuthProvider';

export const initialState = {
  energyFlow: {
    energyCharge: 0,
    energyDischarge: 0,
    gridExport: 0,
    gridImport: 0,
    pvGeneration: 0,
    selfSufficiencyPercent: 0,
    totalConsumption: 0,
  },
  installations: {
    lastWeek: 0,
    periodOverPeriodPercent: 0,
    thisWeek: 0,
    total: 0,
  },
  installers: [
    {
      companyName: '',
      email: '',
      firstName: '',
      installations: '',
      isActive: false,
      lastActivityAt: '',
      lastName: '',
      phone: '',
    },
  ],
  inventory: {
    plants: 0,
    subUnits: 0,
  },
};

export const DashboardProvider = ({ children }: TProviderProps) => {
  const [dashboardDataState, setDashboardDataState] = useState(initialState);
  const [error, setError] = useState(false);
  const { authContextData } = useAuthData();
  const { authenticated = false } = authContextData;

  useEffect(() => {
    if (authenticated) {
      (async () => {
        try {
          const response = await getDashboardData();
          onGetDashboardSuccess(response.data);
        } catch (error) {
          setError(true);
        }
      })();
    }
  }, [authenticated]);

  const onGetDashboardSuccess = (data: TDashboardResponse) => {
    setDashboardDataState(data);
  };

  return (
    <DashboardContext.Provider
      value={{
        dashboardDataState: dashboardDataState,
        onGetDashboardSuccess,
        errorWhileFetchingData: error,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => useContext(DashboardContext);
