import { useState } from 'react';

import { IconButton } from '@mui/material';

import { EyeIcon } from '@/components/icons/EyeIcon';
import { EyeSlashIcon } from '@/components/icons/EyeSlashIcon';

import { FormikText, TFormikTextProps } from './FormikText';

type TFormikPasswordProps<T> = TFormikTextProps<T> & {
  toggleButtonTestId?: string;
};

export const FormikPassword = <PError extends unknown>({
  toggleButtonTestId,
  ...props
}: TFormikPasswordProps<PError>) => {
  const [explicit, setExplicit] = useState(false);

  const Icon = explicit ? EyeSlashIcon : EyeIcon;
  return (
    <FormikText
      type={explicit ? 'text' : 'password'}
      endAdornment={
        <IconButton
          size="small"
          onClick={() => setExplicit(!explicit)}
          sx={({ palette }) => ({
            color: palette.text.primary,
            marginRight: '-5px',
          })}
          data-testid={toggleButtonTestId}
        >
          <Icon fontSize="small" />
        </IconButton>
      }
      {...props}
    />
  );
};
