import { colors, contrast } from '../colors';
import { createThemeMode } from './common';

export const lightTheme = createThemeMode(
  'light',
  {
    primary: colors.brandBlack,
    secondary: colors.white,
    textPrimary: colors.brandBlack,
    textSecondary: colors.darkerGrey,
    textDisabled: colors.darkGrey,
    textIcon: colors.almostBlack,
    backgroundMain: colors.bgBlue,
    backgroundPaper: colors.white,
    backgroundGradient: `linear-gradient(225deg, ${colors.lightestGrey} 0%, ${colors.white} 100%)`,
    inputPlaceholder: colors.darkGrey,
    menuItemFocused: colors.lighterGrey,
    divider: colors.mediumGrey,
    contrast,
  },
  {
    1: '0px 1px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.16)',
    2: '0px 2px 8px rgba(0, 0, 0, 0.08)',
    3: '0px 2px 8px rgba(0, 0, 0, 0.16)',
    5: '0px 2px 3px 0px rgba(0, 0, 0, 0.24), 0px 6px 12px 6px rgba(0, 0, 0, 0.12)',
  },
);
