import { useTranslation } from 'react-i18next';

import { Box, Button, Typography } from '@mui/material';
import { InferType } from 'yup';

import { FormikText } from '@/components/formik/FormikText';
import { InfoBox } from '@/components/InfoBox';
import { GenericModalFooter } from '@/components/modal/GenericModalFooter';

import { TOnSubmit } from '@/types/formTypes';

import { mockedProfile } from '@/mocks/profile';
import { enterEmailsSchema } from '@/validation/settings';

import { FormikForm } from '../formik/FormikForm';
import { FormikSubmit } from '../formik/FormikSubmit';
import { styles } from './ChangeEmail.styles';
import { STEPS } from './config';
import { useAuthData } from '@/contexts/AuthProvider';

type TEnterEmailsFormData = InferType<typeof enterEmailsSchema>;

type TEnterEmailsFormProps = {
  initialEmail: string;
  changeStep: (step: STEPS) => void;
  setEmail: (email: string) => void;
  onError: (error: string) => void;
  closeModal: () => void;
};

export const EnterEmailsForm = ({
  initialEmail,
  changeStep,
  setEmail,
  onError,
  closeModal,
}: TEnterEmailsFormProps) => {
  const { authContextData } = useAuthData();
  const { email: currentUserEmail } = authContextData;

  const { t } = useTranslation();
  const initialValues: TEnterEmailsFormData = { email: initialEmail, confirmEmail: initialEmail };

  const onSubmit: TOnSubmit<TEnterEmailsFormData> = ({ email }, { setSubmitting }) => {
    if (email === currentUserEmail) {
      onError(t('changeEmail.cantMatchCurrent'));
      setSubmitting(false);
      return;
    }
    setEmail(email);
    changeStep(STEPS.CONFIRM_PASSWORD);
  };

  return (
    <FormikForm
      initialValues={initialValues}
      validationSchema={enterEmailsSchema}
      onSubmit={onSubmit}
      sx={styles.form}
    >
      <Box sx={styles.fields}>
        <Box sx={styles.header}>
          <Typography variant="h5">{t('changeEmail.title')}</Typography>
        </Box>

        <FormikText name="email" label="changeEmail.newEmail" />
        <FormikText name="confirmEmail" label="changeEmail.confirmEmail" />

        {/* <InfoBox color="blue" variant="body2" sx={styles.infoBox}>
          {t('changeEmail.emailInfo')}
        </InfoBox> */}
      </Box>

      <GenericModalFooter>
        <Button variant="outlined" onClick={() => closeModal()}>
          {t('common.cancel')}
        </Button>

        <FormikSubmit>{t('common.next')}</FormikSubmit>
      </GenericModalFooter>
    </FormikForm>
  );
};
