import { typedStyles } from '@/utils/styleUtils';
import { colors } from '@/themesConfig/colors';

export const styles = typedStyles({
  modal: {
    width: 'min(90%, 680px)',
    height: 'min(90%, 680px)',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    height: '80px',
    backgroundColor: colors.lightGrey,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '22px',
  },
  headingText: {
    fontSize: 22,
  },
  imageSize: {
    maxWidth: '240px',
    maxHeight: 'auto',
  },
  imageContainer: {
    flex: 1,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'space-evenly',
    paddingTop: '22px',
  },
  imageCard: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '12px',
    alignItems: 'flex-start',
    paddingLeft: '12px',
  },
  button: {
    width: 'fit-content',
    alignSelf: 'center',
    marginBottom: '12px',
  },
});
