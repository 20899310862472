export const HP_ROUTES = {
  dashboard: '/dashboard',
  customers: '/customers',
  devices: '/devices',
  schedule: '/schedule',
  installers: '/installers',
  timeline: '/timeline',
  analytics: '/analytics',
  // notifications: '/notifications',
  // settings: '/settings',
  support: '/support',
  resources: '/resources',
  checkWarranty: '/check-warranty',
};

export const HP_ROUTES_PATHS = Object.values(HP_ROUTES);
