import { SvgIcon, SvgIconProps } from '@mui/material';

export const CrossCircle = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.29169 9.99999C2.29169 5.74279 5.74283 2.29166 10 2.29166C14.2572 2.29166 17.7084 5.74279 17.7084 9.99999C17.7084 14.2572 14.2572 17.7083 10 17.7083C5.74283 17.7083 2.29169 14.2572 2.29169 9.99999ZM10 1.04166C5.05247 1.04166 1.04169 5.05244 1.04169 9.99999C1.04169 14.9475 5.05247 18.9583 10 18.9583C14.9476 18.9583 18.9584 14.9475 18.9584 9.99999C18.9584 5.05244 14.9476 1.04166 10 1.04166ZM7.94196 7.05805C7.69788 6.81397 7.30216 6.81397 7.05808 7.05805C6.814 7.30213 6.814 7.69785 7.05808 7.94193L9.11614 9.99999L7.05808 12.058C6.814 12.3021 6.814 12.6979 7.05808 12.9419C7.30216 13.186 7.69788 13.186 7.94196 12.9419L10 10.8839L12.0581 12.9419C12.3022 13.186 12.6979 13.186 12.942 12.9419C13.186 12.6979 13.186 12.3021 12.942 12.058L10.8839 9.99999L12.942 7.94193C13.186 7.69785 13.186 7.30213 12.942 7.05805C12.6979 6.81397 12.3022 6.81397 12.0581 7.05805L10 9.11611L7.94196 7.05805Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
