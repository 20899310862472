import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TicketsTable } from './ticketsTable/TicketsTable';
import { extractTicketVM, formatTickets } from './utils';
import { useSupportContext } from '@/contexts/SupportProvider';
import { GenericTicketTable } from './types';

const GenericTicketTable = ({
  closedTabSelected = false,
  tickets = [],
  totalTickets,
  isLoading = false,
}: GenericTicketTable) => {
  const { t } = useTranslation();
  const { triggerFetchOpenOrClosedTickets } = useSupportContext();
  type TTicketViewModel = ReturnType<typeof extractTicketVM>;

  const [formattedTickets, setFormattedTickets] = useState<TTicketViewModel[]>([]);
  const [error, setError] = useState<string | null>(null);

  const triggerIfMoreTicketsOnBackend = () => {
    if (totalTickets && tickets.length < totalTickets && !isLoading) {
      triggerFetchOpenOrClosedTickets(closedTabSelected ? 'closed' : 'open');
    }
  };

  useEffect(() => {
    const formattedTickets = formatTickets(tickets, t);
    setFormattedTickets(formattedTickets);
  }, [tickets]);

  return (
    <TicketsTable
      tickets={formattedTickets}
      showLoader={isLoading}
      textIfNoTickets={
        closedTabSelected
          ? t('support.noTicketsAvaialble.closed')
          : t('support.noTicketsAvaialble.open')
      }
      triggerOnReachingBottom={triggerIfMoreTicketsOnBackend}
    />
  );
};

export default GenericTicketTable;
