import * as Yup from 'yup';

import { newPassword, optionalString, requiredString } from './common';

export const createPasswordSchema = Yup.object({
  newPassword,
  confirmPassword: requiredString.oneOf([Yup.ref('newPassword'), ''], 'forgotPassword.dontMatch'),
}).required();

export const locationSchema = Yup.object({
  streetAddress: requiredString,
  secondaryAddress: optionalString,
  city: requiredString,
  state: requiredString,
  zip: requiredString.max(5),
}).required();

export const signInSchema = Yup.object({
  email: requiredString.email('errors.form.email'),
  password: requiredString,
}).required();
