import { createContext, useContext, useMemo, useState } from 'react';

import { Fade, Modal } from '@mui/material';

import { COMPONENTS } from '@/contexts/ModalsProvider/config';

import type { TProviderProps } from '../types';
import type { TModalName, TModalProps, TModalsContext, TModalStates } from './types';

const ModalsContext = createContext({} as TModalsContext);

const makePatch =
  (name: TModalName, patch: Partial<TModalStates[TModalName]>) => (prev: TModalStates) => ({
    ...prev,
    [name]: {
      ...prev[name],
      ...patch,
    },
  });

export const ModalsProvider = ({ children }: TProviderProps) => {
  const [modals, setModals] = useState<TModalStates>({});

  const stack = useMemo(() => Object.entries(modals), [modals]);

  const api = useMemo(
    () => ({
      open: (name: TModalName, props: TModalProps = {}) => {
        setModals(makePatch(name, { props, isOpen: true, lock: () => true }));
      },

      close: (name: TModalName) => {
        setModals(makePatch(name, { isOpen: false }));
      },

      lock: (name: TModalName, key: () => boolean) => {
        setModals(makePatch(name, { lock: key }));
      },
    }),
    [],
  );

  const onClose = (name: TModalName) => {
    if (modals[name]?.lock()) {
      api.close(name);
    }
  };

  return (
    <ModalsContext.Provider value={api}>
      {children}

      {stack.map(([prop, { props, isOpen }]) => {
        const name = prop as TModalName;
        const Content = COMPONENTS[name] as (props: TModalsContext) => JSX.Element;

        return (
          <Modal key={name} open={isOpen} onClose={() => onClose(name)}>
            <Fade in={isOpen}>
              <div>
                <Content {...api} {...props} />
              </div>
            </Fade>
          </Modal>
        );
      })}
    </ModalsContext.Provider>
  );
};

export const useModals = () => useContext(ModalsContext);
