import { SvgIcon, SvgIconProps } from '@mui/material';

export const Search = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m21 21-3.6-3.6m0 0A9 9 0 0 0 11 2a9 9 0 1 0 6.4 15.4Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="1.5"
      fill="none"
    />
  </SvgIcon>
);
