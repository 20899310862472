import { Trans, useTranslation } from 'react-i18next';

import { Link, Typography } from '@mui/material';

import type { TModalsContext } from '@/contexts/ModalsProvider/types';

import { GenericModal } from '@/components/modal/GenericModal';

import { colors } from '@/themesConfig/colors';

import { GenericModalClose } from '../modal/GenericModalClose';
import { Content } from './Content';
import { styles } from './Faq.styles';

export const Faq = ({ close }: TModalsContext) => {
  const { t } = useTranslation();

  return (
    <GenericModal sx={styles.root}>
      <GenericModalClose
        onClick={() => {
          close('profileMenu.faq');
        }}
      />
      <Typography marginX={3} variant="h4" textAlign="center">
        {t('faq.title')}
      </Typography>

      <Typography marginY={6} marginX={3} whiteSpace="pre-line">
        <Trans i18nKey="faq.description">
          <Link
            sx={{ color: colors.brandBlue, textDecorationColor: colors.brandBlue, fontWeight: 500 }}
            href="mailto:support@hyperian.com"
          />
        </Trans>
      </Typography>

      <Content />
    </GenericModal>
  );
};
