import { SvgIcon, SvgIconProps } from '@mui/material';

export const Cross = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      d="M5.75 18L17.25 6M5.75 6L17.25 18"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
