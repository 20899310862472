import { Dispatch, SetStateAction, useCallback, useState, Fragment } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import ClearIcon from '@mui/icons-material/Clear';
import { Box, Fade, FormHelperText, IconButton, Typography } from '@mui/material';

import { ReservedLines } from '@/components/reservedLines/ReservedLines';

import { styles } from './UploadFiles.styles';
import { UploadFilePlaceholder } from './UploadFilesPlaceholder';

type TUploadImageProps = {
  filesList: File[];
  setFilesList: Dispatch<SetStateAction<File[]>>;
};

export const UploadFiles = ({ filesList, setFilesList }: TUploadImageProps) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    noClick: true,
    multiple: true,

    onDropAccepted: useCallback((files) => {
      setError(null);
      setFilesList((prev) => [...prev, ...files]);
    }, []),
  });

  const onDeleteButtonClick = (index: number) => {
    setFilesList((prev) => prev.filter((_, rowIndex) => index !== rowIndex));
  };

  return (
    <>
      <Box {...getRootProps()} sx={styles.dropZone}>
        <input {...getInputProps()} />

        <UploadFilePlaceholder select={() => open()} />

        <Fade in={isDragActive}>
          <Box sx={styles.dropZoneOverlay}>
            <Typography variant="body2">{t('uploadImage.dropHere')}</Typography>
          </Box>
        </Fade>
      </Box>
      <Box sx={{ marginTop: 1 }}>
        {filesList.map((fileItem, index) => (
          <Fragment key={index}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Typography key={fileItem.lastModified}>{fileItem.name}</Typography>
              <IconButton onClick={() => onDeleteButtonClick(index)}>
                <ClearIcon />
              </IconButton>
            </Box>

            <FormHelperText error>{error || <ReservedLines />}</FormHelperText>
          </Fragment>
        ))}
      </Box>
    </>
  );
};
