import type { TFunction } from 'react-i18next';

import {
  categoryToDisplayKey,
  ticketStatusToDisplayKey,
  subjectToDisplayKey,
} from '@/config/support';
import { getDate, getTime } from '@/utils/dateUtils';

import { TSupportTicketNew, TSupportTicketNewWithPlantDetails } from '@/types/support';

export const extractTicketVM = (ticket: TSupportTicketNew, t: TFunction) => {
  return {
    id: ticket.id,
    entity: ticket,
    created: getDate(ticket.createdTime),
    updated: getDate(ticket.modifiedTime),
    subject: t(ticket.subject),
    status: t(ticketStatusToDisplayKey(ticket.status)),
    collapsableRows: [
      {
        id: '',
        description: ticket.description ?? '',
      },
    ],
    productName: ticket.longName ?? '',
    unitSerialNumber: ticket.cf.cf_product_sn ?? '',
    issueDate: getDate(ticket.createdTime) ?? '',
    plantId: String(ticket.cf.cf_plant_id ?? ''),
  };
};

export const getTabs = (t: TFunction) => [
  {
    key: 'openTickets' as const,
    tabTitle: t('support.tabs.openTickets'),
  },
  { key: 'completedTickets' as const, tabTitle: t('support.tabs.completedTickets') },
];

export type TTabKey = ReturnType<typeof getTabs>[number]['key'];

export const formatTickets = (tickets: TSupportTicketNew[], t: TFunction) => {
  const formattedTickets = tickets.map((ticket) => extractTicketVM(ticket, t));

  return formattedTickets;
};

type TTicketViewModel = ReturnType<typeof extractTicketVM>;

export const filterTickets = (
  closedTabSelected: boolean,
  tickets: TTicketViewModel[],
  t: TFunction,
) => {
  const filteredTickets = tickets.filter(
    (ticket) =>
      (closedTabSelected && ticket.status === t('support.statuses.closed')) ||
      (!closedTabSelected && ticket.status !== t('support.statuses.closed')),
  );
  return filteredTickets;
};

export const destructAPIResponse = (data: TSupportTicketNewWithPlantDetails) => {
  return {
    ...data.ticket,
    ...data.plantModel,
    numberOfBatteries: data.numberOfBatteries,
  };
};
export type TOptions = {
  label: string;
  value: string;
};

export type TProduct = {
  plantModelId: number;
  shortName: string;
  longName: string;
  zohoProductId: string;
};

export const returnProductOptions = (allZohoProducts: TProduct[]): TOptions[] => {
  const productOptions = allZohoProducts.map((eachProduct) => ({
    label: eachProduct.longName,
    value: String(eachProduct.zohoProductId),
  }));
  return productOptions;
};
