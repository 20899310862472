import { typedStyles } from '@/utils/styleUtils';

export const styles = typedStyles({
  root: {
    width: 'min(80%, 1284px)',
    height: '90%',
    paddingTop: 4,
    display: 'flex',
    flexDirection: 'column',
  },

  formWrapper: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    overflow: 'auto',
    paddingTop: 3,
  },

  form: {
    display: 'flex',
    height: '100%',
    maxWidth: 740,
    width: '-webkit-fill-available',
    flexDirection: 'column',
    alignSelf: 'center',
  },

  fieldsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 2,
  },

  fields: {
    paddingX: 6,
    flex: 1,
  },

  // fieldsWithCategory: {
  //   marginTop: 'unset',
  // },

  footer: {
    display: 'flex',
    justifyContent: 'center',
    zIndex: 1,
  },
});
