import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Typography } from '@mui/material';

import type { TModalsContext } from '@/contexts/ModalsProvider/types';

// import { ETicketCategory, ETicketStatus, ETicketSubject } from '@/config/support';
// import { fromTemplate } from '@/utils/arrayUtils';

import { GenericModal } from '@/components/modal/GenericModal';
// import { mockedTickets } from '@/mocks/tickets';

import { GenericModalClose } from '../modal/GenericModalClose';
import { GenericModalFooter } from '../modal/GenericModalFooter';
import { SearchInput } from '../searchInput/SearchInput';
import { Tabs } from '../tabs/Tabs';
import { styles } from './Support.styles';
import { TicketsTable } from './ticketsTable/TicketsTable';
import {
  TTabKey,
  extractTicketVM,
  getTabs,
  filterTickets,
  formatTickets,
  destructAPIResponse,
} from './utils';
import { useSupportContext } from '@/contexts/SupportProvider';
import { searchSupportTickets } from '@/services/support';
import useDebounce from '@/customHooks/useDebounce';
import { stringToStatusEnum } from '@/config/support';
import { SubmitError } from '@/components/submitError/SubmitError';
import GenericTicketTable from './GenericTicketTable';

export const Support = ({ open, close }: TModalsContext) => {
  const { t } = useTranslation();
  const tabs = getTabs(t);
  const [selectedTab, setSeletectedTab] = useState<TTabKey>(tabs[0].key);
  const { supportDataState, loadingData, openTicketsList, closedTicketsList } = useSupportContext();
  type TTicketViewModel = ReturnType<typeof extractTicketVM>;
  const { tickets = [] } = supportDataState;

  const {
    tickets: openTickets = [],
    loading: openTicketLoading = false,
    totalTicketsOnBackend: totalOpenTicketsOnBackend = 0,
  } = openTicketsList;

  const {
    tickets: closedTickets = [],
    loading: closedTicketsLoading = false,
    totalTicketsOnBackend: closedTicketsOnBackend = 0,
  } = closedTicketsList;

  const [searchValue, setSearchValue] = useState('');

  const debouncedSearchValue = useDebounce(searchValue, 500);

  const [closedTabSelected, setCloseTabSelected] = useState<boolean>(false);
  const [formattedTickets, setFormattedTickets] = useState<TTicketViewModel[]>([]);
  const [filteredTickets, setFilteredTickets] = useState<TTicketViewModel[]>([]);
  const [totalSearchCount, setTotalSearchCOunt] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const closedTabSelected = selectedTab === 'completedTickets' ? true : false;
    setCloseTabSelected(closedTabSelected);
  }, [selectedTab]);

  useEffect(() => {
    const filteredTickets = filterTickets(closedTabSelected, formattedTickets, t);
    setFilteredTickets(filteredTickets);
  }, [closedTabSelected]);

  const handleCreateTicketClick = () => {
    close('support.base');
    open('support.createTicket', {
      submitTicketHandler: (ticket: any) => console.log({ ticket }),
    });
  };

  const searchTickets = async (query: string, fromValue: number) => {
    try {
      setLoading(true);
      const response = await searchSupportTickets({
        searchText: query,
        from: fromValue,
      });
      if (searchValue) {
        const tickets = response.data.data ?? [];
        const totalCount = response.data.count;
        if (totalSearchCount === null) {
          setTotalSearchCOunt(totalCount);
        }
        const refactoredTickets = tickets.map((eachTicket) => destructAPIResponse(eachTicket));
        const statusUpdatedTickets = refactoredTickets.map((ticket) => {
          return {
            ...ticket,
            status: stringToStatusEnum(ticket.status),
          };
        });
        const formattedTickets = formatTickets(statusUpdatedTickets, t);
        setFormattedTickets((initial) => [...initial, ...formattedTickets]);
        const filteredTickets = filterTickets(closedTabSelected, formattedTickets, t);
        setFilteredTickets(filteredTickets);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(t('dashboard.ticketsDataError'));
    }
  };

  const triggerIfOnBotton = () => {
    if (totalSearchCount && formattedTickets.length < totalSearchCount && !loading) {
      searchTickets(debouncedSearchValue, formattedTickets.length);
    }
  };

  useEffect(() => {
    if (debouncedSearchValue !== '') {
      //fetching initial tickets so passing fromValue as 0
      searchTickets(debouncedSearchValue, 0);
    }
  }, [debouncedSearchValue]);

  return (
    <GenericModal sx={styles.root}>
      <GenericModalClose
        onClick={() => {
          close('support.base');
        }}
      />
      <SubmitError
        error={error}
        dismiss={() => setError(null)}
        sx={{ top: 0, right: 0, padding: 1, marginTop: 10, marginLeft: 10 }}
      />
      <Typography marginX={3} variant="h5" textAlign="center" marginBottom={3}>
        {t('support.title')}
      </Typography>
      <SearchInput
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        sx={{ maxWidth: 740 }}
      />
      <Tabs tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSeletectedTab} />
      {searchValue !== '' ? (
        <TicketsTable
          tickets={filteredTickets}
          showLoader={loading || loadingData}
          textIfNoTickets={
            closedTabSelected
              ? t('support.noTicketsAvaialble.closed')
              : t('support.noTicketsAvaialble.open')
          }
          triggerOnReachingBottom={triggerIfOnBotton}
        />
      ) : (
        <GenericTicketTable
          closedTabSelected={closedTabSelected}
          tickets={closedTabSelected ? closedTickets : openTickets}
          totalTickets={closedTabSelected ? closedTicketsOnBackend : totalOpenTicketsOnBackend}
          isLoading={closedTabSelected ? closedTicketsLoading : openTicketLoading}
        />
      )}
      <GenericModalFooter sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={handleCreateTicketClick}>{t('support.createTicket.title')}</Button>
      </GenericModalFooter>
    </GenericModal>
  );
};
