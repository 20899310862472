import { typedStyles } from '@/utils/styleUtils';

import { colors } from '@/themesConfig/colors';

export const styles = typedStyles({
  modal: {
    width: 'min(80%, 884px)',
    height: '90%',
    paddingTop: 6,
    display: 'flex',
    flexDirection: 'column',
  },

  title: {
    marginBottom: 6,
  },

  subtitle: {
    marginBottom: 4,
  },

  container: ({ spacing }) => ({
    padding: spacing(1, 3),
    overflowY: 'auto',
  }),

  columns: {
    display: 'flex',
    position: 'relative',
    maxWidth: 830,
    marginTop: 5,
    justifyContent: 'space-between',
    gap: 2,
    flexWrap: 'wrap',
  },

  column: {
    flex: 1,
    flexDirection: 'column',
    minWidth: 250,
    maxWidth: 'auto',
  },

  infoBox: {
    gap: 1.25,
    padding: 2.5,
    flexDirection: 'column',
  },

  field: {
    '.MuiTypography-root': {
      fontWeight: 500,
    },
    '.MuiInputLabel-root': {
      fontWeight: 500,
    },
  },

  businessAddress: {
    '.MuiTypography-root': {
      marginTop: 3,
      whiteSpace: 'pre-line',
    },
  },

  icon: {
    color: colors.brandBlue,
  },

  infoText: {
    fontSize: 14,
    marginTop: 1.2,
  },
});
