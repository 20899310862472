import { useTranslation } from 'react-i18next';

import { Grid, TypographyProps, styled } from '@mui/material';

import { TooltipedOverflow } from '@altirllc/shared/ui/components/tooltipedOverflow/TooltipedOverflow';

import { capitalize } from '@/utils/stringUtils';

import { AlertIcon } from '@/components/icons/AlertIcon';
import { ReservedLines } from '@/components/reservedLines/ReservedLines';

import type { TErrorProps } from './types';

type TError = string | ({ key: string } & Record<string, unknown>);

type TSimpleErrorProps = TErrorProps<TError> & {
  withIcon?: boolean;
  color?: TypographyProps['color'];
};

const TooltipedError = styled(TooltipedOverflow)({
  fontFamily: 'inherit',
  fontWeight: 'inherit',
  fontSize: 'inherit',
  lineHeight: 'inherit',
});

export const SimpleError = ({ error, label, errorsShown, withIcon, color }: TSimpleErrorProps) => {
  const { t } = useTranslation();

  if (!error || errorsShown === false) {
    return <ReservedLines />;
  }

  const { key, ...props } = typeof error === 'string' ? { key: error } : error;
  const errorMessage = t(key, { ...props, label: capitalize(label) });

  if (withIcon === false) {
    return <TooltipedError color={color} text={errorMessage} />;
  }

  return (
    <Grid alignItems="center">
      <AlertIcon sx={{ marginRight: 0.5, fontSize: 16 }} />
      <TooltipedError color={color} text={errorMessage} />
    </Grid>
  );
};
