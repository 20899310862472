import { useLayoutEffect, useRef } from 'react';

type TReservedLinesProps = {
  lines?: number;
};

export const ReservedLines = ({ lines = 1 }: TReservedLinesProps) => {
  const ref = useRef<HTMLSpanElement>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      const styles = window.getComputedStyle(ref.current);
      const height = parseFloat(styles.lineHeight) * lines;

      ref.current.style.setProperty('height', `${height}px`);
    }
  }, [lines]);

  return <span ref={ref} style={{ display: 'block' }} />;
};
