import { useEffect, useState } from 'react';

import { Alert, Snackbar, Typography } from '@mui/material';

import { combineStyles, typedStyles } from '@/utils/styleUtils';

import { TSxStyles } from '@/types/common';

type TSuccessMessageProps = {
  message: JSX.Element | string | null;
  dismiss: () => void;
  sx?: TSxStyles;
};

const styles = typedStyles({
  snackbar: {
    bottom: ['100%', '100%'],

    marginTop: 7,
  },

  alert: {
    width: ' 100%',
    borderRadius: 8,

    '.MuiAlert-icon': {
      display: 'none',
    },

    '.MuiAlert-message': {
      flex: 1,
      textAlign: 'center',
    },

    '.MuiAlert-action': {
      padding: 0,
      margin: 0,
      alignSelf: 'center',
    },
  },
});

export const SuccessMessage = ({ sx, message: extMessage, dismiss }: TSuccessMessageProps) => {
  const [message, setMessage] = useState(extMessage);

  useEffect(() => {
    if (extMessage) {
      setMessage(extMessage);
    }
  }, [extMessage]);

  return (
    <Snackbar
      open={!!extMessage}
      onClose={dismiss}
      sx={combineStyles(styles.snackbar, sx)}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={3000}
    >
      <Alert color="success" variant="filled" sx={styles.alert} elevation={1}>
        <Typography whiteSpace="pre-line" fontWeight={500} color="secondary.main">
          {message}
        </Typography>
      </Alert>
    </Snackbar>
  );
};
