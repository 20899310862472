import { isValidPhoneNumber } from 'react-phone-number-input';

import * as Yup from 'yup';

declare module 'yup' {
  interface BaseSchema<TCast, TContext, TOutput> {
    onlyIf(dep: string): BaseSchema<TCast, TContext, TOutput>;
  }
}

Yup.addMethod<Yup.BaseSchema>(Yup.mixed, 'onlyIf', function onlyIf(dep: string) {
  return this.when(dep, (condition, schema) => (condition ? schema : Yup.mixed()));
});

export const optionalString = Yup.string().ensure();

export const requiredString = optionalString.required('errors.form.required');

export const requiredNumber = Yup.number().required('errors.form.required');

export const confirmBoolean = Yup.boolean().required().default(false).isTrue();

export const makeSelectString = <POption extends string>(
  options: POption[] | ReadonlyArray<POption>,
) =>
  Yup.string()
    .oneOf(options as POption[], 'errors.form.oneOf')
    .required('errors.form.required') as Yup.StringSchema<POption>;

export const makeEnumString = <POptions extends string>(options: Record<string, POptions>) =>
  makeSelectString<POptions>(Object.values(options));

export const requiredPhone = requiredString.test('phoneNumber', 'errors.form.phone', (phone) =>
  isValidPhoneNumber(phone),
);

export const requiredEmail = requiredString.email('errors.form.email');
