import { typedStyles } from '@/utils/styleUtils';

export const styles = typedStyles({
  root: {
    width: 'min(80%, 1284px)',
    height: '90%',
    paddingTop: 6,
    display: 'flex',
    flexDirection: 'column',
  },
});
