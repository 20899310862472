import { typedStyles } from '@/utils/styleUtils';

export const styles = typedStyles({
  modal: {
    width: 'min(80%, 480px)',
    height: 'min(80%, 650px)',
  },

  header: {
    position: 'relative',
    marginBottom: 3,
  },

  form: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    overflow: 'auto',
    paddingTop: 8,
  },

  fields: {
    paddingX: 6,
    flex: 1,
  },
});
