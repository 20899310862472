import { Suspense, lazy, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { AUTH_ROUTES_PATHS, HP_ROUTES_PATHS, LANDING_ROUTES_PATHS } from '@/config/routes';

import { useAuthData } from './contexts/AuthProvider';
import { HYPERIAN_ROUTES } from '@/routes/HyperianRoutes';

// const LandingPage = lazy(() => import('./pages/Landing/LandingPage'));
const AdminPage = lazy(() => import('./pages/Admin/AdminPage'));
const AuthPage = lazy(() => import('./pages/Auth/AuthPage'));
import { getProfileDetails } from '@/services/profile';
import PrivateRoute from './router/PrivateRoute';

export const App = () => {
  const { authContextData, setUserData } = useAuthData();
  const { authenticated = false, userDataLoaded = false } = authContextData;
  const history = useHistory();

  useEffect(() => {
    if (!authenticated) {
      history.push(HYPERIAN_ROUTES.signIn);
    }
  }, [authenticated, history]);

  useEffect(() => {
    if (authenticated && !userDataLoaded) {
      (async () => {
        try {
          const profileResponse = await getProfileDetails();
          const userData = profileResponse.data;
          setUserData(userData);
        } catch (error) {
          console.log('Error in loading user data', error);
        }
      })();
    }
  }, [authenticated, userDataLoaded]);

  return (
    <Suspense fallback="">
      <Switch>
        <Route path={LANDING_ROUTES_PATHS} exact>
          <Redirect to="/dashboard" />
        </Route>

        <Route path={AUTH_ROUTES_PATHS} exact>
          <AuthPage />
        </Route>

        <PrivateRoute path={HP_ROUTES_PATHS} exact>
          <AdminPage />
        </PrivateRoute>
      </Switch>
    </Suspense>
  );
};
