import { alpha, createTheme } from '@mui/material';
// eslint-disable-next-line no-restricted-imports
import defaultShadows, { Shadows } from '@mui/material/styles/shadows';

import { CheckBox } from '@/components/icons/CheckBox';
import { CheckBoxChecked } from '@/components/icons/CheckBoxChecked';

import { colors } from '../colors';
import { MetropolisMedium, MetropolisRegular, MetropolisSemiBold } from '../fonts';
import { breakpoints } from './breakpoints';
import type { CustomPalette, Mode, ShadowsOverrides } from './types';
import { typography } from './typography';

const spacing = 8;

const borderRadius = 4;

const toAutofillStyles = (bgColor: string, color: string) => ({
  '&, &:active, &:focus': {
    WebkitTextFillColor: color,
    WebkitBoxShadow: `0px 0px 0px 1000px ${bgColor} inset`,
    transition: 'background-color 5000s 0s',
    boxSizing: 'border-box',
  },
});

const overrideShadows = (overrides: ShadowsOverrides = {}) => {
  const shadows: Shadows = [...defaultShadows];

  Object.entries(overrides).forEach(([index, value]) => {
    shadows[Number(index)] = value;
  });

  return shadows;
};

export const createThemeMode = (mode: Mode, palette: CustomPalette, shadows?: ShadowsOverrides) =>
  createTheme({
    spacing,
    typography,
    breakpoints,
    shape: { borderRadius },
    shadows: overrideShadows(shadows),
    palette: {
      mode,
      primary: {
        main: palette.primary,
      },
      secondary: {
        main: palette.secondary,
      },
      text: {
        primary: palette.textPrimary,
        secondary: palette.textSecondary,
        disabled: palette.textDisabled,
      },
      background: {
        default: palette.backgroundMain,
        paper: palette.backgroundPaper,
      },
      info: {
        main: colors.systemBlue,
      },
      error: {
        main: colors.systemRed,
        dark: colors.systemDarkRed,
      },
      warning: {
        main: colors.systemOrange,
      },
      success: {
        main: colors.systemGreen,
        dark: colors.systemDarkGreen,
      },
      divider: palette.divider,
      custom: palette,
    },
    components: {
      // @font-face: [reg, med, bold] fails to set strict font-weights for some reason
      MuiCssBaseline: {
        styleOverrides: `
          html, body, #root {
            height: 100%;
          }

          body {
            margin: 0;
            background-color: ${palette.backgroundMain};
            font-family: monospace; // to show where Typography is not used
          }

          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          input[type=number] {
            -moz-appearance: textfield;
          }

          input[type="password"]::-ms-reveal {
            display: none;
          }

          @font-face {
            font-family: Metropolis;
            font-weight: 400;
            src: local(Metropolis), local(Metropolis-Regular), url(${MetropolisRegular}) format('opentype');
          }

          @font-face {
            font-family: Metropolis;
            font-weight: 500;
            src: local(Metropolis-Medium), url(${MetropolisMedium}) format('opentype');
          }

          @font-face {
            font-family: Metropolis;
            font-weight: 600;
            src: local(Metropolis-SemiBold), url(${MetropolisSemiBold}) format('opentype');
          }
        `,
      },

      MuiContainer: {
        styleOverrides: {
          maxWidthLg: ({ theme }) => ({
            [theme.breakpoints.up('lg')]: {
              maxWidth: 1232,
            },
          }),
        },
      },

      MuiGrid: {
        styleOverrides: {
          root: {
            display: 'flex',
          },
        },
      },

      MuiFormControl: {
        defaultProps: {
          variant: 'standard',
          margin: 'normal',
          fullWidth: true,
        },

        styleOverrides: {
          marginNormal: {
            marginTop: spacing * 0.5,
            marginBottom: 0.75 * spacing,

            '&:first-of-type': {
              marginTop: 0,
            },
          },
        },
      },

      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: palette.inputPlaceholder,
            lineHeight: typography.body1.lineHeight,

            '&.MuiInputLabel-standard': {
              transform: 'translate(0, 25px) scale(1)',
            },

            '&.MuiInputLabel-outlined, &.MuiInputLabel-filled': {
              transform: `translate(${2 * spacing - 1}px, ${1.5 * spacing - 1}px) scale(1)`,
            },

            '&.Mui-error': {
              color: palette.inputPlaceholder,
            },
          },

          shrink: {
            color: palette.textSecondary,

            '&.MuiInputLabel-standard': {
              transform: 'translate(0, 0) scale(0.75)',
            },

            '&.MuiInputLabel-outlined, &.MuiInputLabel-filled': {
              transform: `translate(${2 * spacing - 1}px, -9px) scale(0.75)`,
            },

            '&.Mui-error': {
              color: palette.primary,
            },
          },
        },
      },

      MuiInput: {
        styleOverrides: {
          root: {
            height: 40,

            '&.Mui-error:after': {
              borderColor: colors.pinkRed,
            },
          },

          input: {
            color: palette.textPrimary,
            fontSize: typography.body1.fontSize,
            boxSizing: 'border-box',

            '&:-webkit-autofill': toAutofillStyles(palette.backgroundPaper, palette.textPrimary),
          },

          multiline: {
            height: 'auto',
            minHeight: 40,
          },
        },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            backgroundColor: palette.contrast[7],
          },

          input: {
            height: 24,
            padding: `${1.5 * spacing - 1}px ${2 * spacing - 1}px`,
            borderRadius: 'ingerit',
          },

          notchedOutline: {
            padding: `0 ${1.5 * spacing - 2}px`,
          },
        },
      },

      MuiFilledInput: {
        styleOverrides: {
          root: {
            borderRadius: 2 * borderRadius,
            backgroundColor: palette.contrast[7],

            '&:before, &:after': {
              display: 'none',
            },
          },

          inputSizeSmall: {
            height: 40,
            padding: 0,
            fontSize: typography.body2.fontSize,
          },

          adornedStart: {
            paddingLeft: spacing,
          },
        },
      },

      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginTop: spacing / 2,
          },
        },
      },

      MuiAutocomplete: {
        styleOverrides: {
          root: {
            '.MuiOutlinedInput-root .MuiAutocomplete-input': {
              padding: '0 4px 0 6px',
            },

            '.MuiInput-root': {
              paddingBottom: 0,
            },
          },
        },
      },

      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            margin: 0,
            gap: 2 * spacing,
            alignItems: 'flex-start',
          },
        },
      },

      MuiCheckbox: {
        defaultProps: {
          icon: CheckBox({}),
          checkedIcon: CheckBoxChecked({ checkColor: palette.contrast[9] }),
          disableRipple: true,
        },

        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },

      MuiRadio: {
        styleOverrides: {
          root: {
            marginRight: 2 * spacing,
            padding: 0,
          },
        },
      },

      MuiSelect: {
        styleOverrides: {
          select: {
            height: '100%',
            lineHeight: '24px',
            padding: '8px 0',

            '&:focus': {
              background: 'none',
            },

            '&.MuiSelect-outlined': {
              padding: `${1.5 * spacing - 1}px ${2 * spacing - 1}px`,
            },
          },
        },
      },

      MuiButton: {
        variants: [
          {
            props: { variant: 'outlined', color: 'error' },
            style: { color: colors.systemDarkRed, borderColor: colors.systemDarkRed },
          },
        ],

        defaultProps: {
          variant: 'contained',
        },

        styleOverrides: {
          root: {
            minWidth: 'var(--buttonMinWidth, 0px)',
            textTransform: 'none',
          },

          sizeSmall: {
            fontSize: typography.caption.fontSize,
            lineHeight: typography.caption.lineHeight,
          },

          sizeMedium: {
            fontSize: typography.body2.fontSize,
            lineHeight: typography.body2.lineHeight,
          },

          sizeLarge: {
            fontSize: typography.subtitle1.fontSize,
            lineHeight: typography.subtitle1.lineHeight,
          },

          contained: {
            fontWeight: 600,
          },
          containedSizeSmall: {
            padding: `${spacing}px ${2 * spacing}px`,
            borderRadius: 2 * borderRadius,
          },
          containedSizeMedium: {
            padding: `${1.25 * spacing}px ${3 * spacing}px`,
            borderRadius: 3 * borderRadius,
          },
          containedSizeLarge: {
            padding: `${2 * spacing}px ${6 * spacing}px`,
            fontWeight: 700,
            borderRadius: 4 * borderRadius,
          },

          outlined: {
            fontWeight: 600,
          },
          outlinedSizeSmall: {
            padding: `${spacing - 1}px ${2 * spacing - 1}px`,
            borderRadius: 28,
          },
          outlinedSizeMedium: {
            padding: `${1.25 * spacing - 1}px ${3 * spacing - 1}px`,
            borderRadius: 3 * borderRadius,
          },
          outlinedSizeLarge: {
            padding: `${2 * spacing - 1}px ${6 * spacing - 1}px`,
            borderRadius: 4 * borderRadius,
          },

          text: {
            padding: 0,
            fontWeight: 500,
            borderBottom: '1px solid',
            borderRadius: 0,

            '&:hover': {
              background: 'none',
            },
          },
          textSizeLarge: {
            fontSize: typography.body1.fontSize,
          },
        },
      },

      MuiMenu: {
        defaultProps: {
          elevation: 3,
        },

        styleOverrides: {
          paper: {
            margin: `${spacing / 2}px 0`,

            '&.MuiPaper-rounded': {
              borderRadius: 4 * borderRadius,
            },
          },

          list: {
            padding: `${spacing}px 0`,
          },
        },
      },

      MuiMenuItem: {
        styleOverrides: {
          root: {
            padding: `${2 * spacing}px ${2.5 * spacing}px`,

            '&.Mui-selected': {
              backgroundColor: alpha(palette.menuItemFocused, 0.6),
            },

            '&, &.Mui-selected': {
              '&.Mui-focusVisible, &:hover': {
                backgroundColor: palette.menuItemFocused,
              },
            },
          },
        },
      },

      MuiTableRow: {
        styleOverrides: {
          root: {
            '&.MuiTableRow-hover': {
              cursor: 'pointer',

              '&:hover': {
                backgroundColor: palette.menuItemFocused,
              },
            },
          },
        },
      },

      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: `${2 * spacing}px ${2 * spacing}px ${2 * spacing}px ${spacing}px`,

            '&:last-of-type': {
              paddingRight: 6 * spacing,
            },

            '&:first-of-type': {
              paddingLeft: 3 * spacing,
            },
          },

          paddingNone: {
            '&, &:first-of-type, &:last-of-type': {
              padding: 0,
            },
          },

          head: {
            paddingTop: 1.5 * spacing,
            paddingBottom: 1.5 * spacing,
            backgroundColor: palette.contrast[8],
            borderTop: `1px solid ${palette.contrast[5]}`,
            borderBottom: `1px solid ${palette.contrast[5]}`,
            lineHeight: typography.caption.lineHeight,
          },

          body: {
            borderBottom: `1px solid ${palette.contrast[5]}`,
          },

          footer: {
            position: 'relative',
            backgroundColor: 'inherit',
          },
        },
      },

      MuiTableSortLabel: {
        styleOverrides: {
          icon: {
            marginTop: -1,
            fontSize: 16,
          },
        },
      },

      MuiAvatar: {
        styleOverrides: {
          img: {
            objectFit: 'contain',
          },

          colorDefault: {
            backgroundColor: palette.contrast[4],
          },

          rounded: {
            borderRadius: 2 * borderRadius,
          },
        },
      },

      MuiSwitch: {
        styleOverrides: {
          root: {
            width: 42,
            height: 24,
            padding: 0,
          },

          switchBase: {
            padding: 0,
            margin: 4,
            transitionDuration: '300ms',

            '&.Mui-checked': {
              transform: 'translateX(18px)',

              '& .MuiSwitch-thumb': {
                backgroundColor: palette.backgroundPaper,
              },

              '&+.MuiSwitch-track': {
                opacity: 1,
              },
            },

            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 1,
            },
          },

          input: {
            width: '400%',
          },

          thumb: {
            width: 16,
            height: 16,
            backgroundColor: palette.primary,
          },

          track: {
            backgroundColor: 'transparent',
            border: `1px solid ${palette.primary}`,
            borderRadius: 12,
            opacity: 1,
          },
        },
      },

      MuiSvgIcon: {
        variants: [
          {
            props: { color: 'text.primary' },
            style: { color: palette.textPrimary },
          },
          {
            props: { color: 'text.secondary' },
            style: { color: palette.textSecondary },
          },
        ],

        styleOverrides: {
          root: {
            fontSize: 'var(--svgIconSize, 1.5rem)',
            verticalAlign: 'top',
          },
        },
      },

      MuiBadge: {
        styleOverrides: {
          colorInfo: {
            color: colors.white,
          },
        },
      },
    },
  });
