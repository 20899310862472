import { useEffect } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { useAuthData } from '@/contexts/AuthProvider';

import { AUTH_ROUTES } from '@/config/routes';

const PrivateRoute = (props: RouteProps) => {
  const { authContextData } = useAuthData();
  const { authenticated = false } = authContextData;
  const { location } = props;

  useEffect(() => {
    if (authenticated) {
      sessionStorage.setItem('hp:intendedPath', location?.pathname || '');
    }
  }, [authenticated, location]);

  if (!authenticated) {
    return <Redirect to={AUTH_ROUTES.signIn} />;
  }

  return <Route {...props} />;
};

export default PrivateRoute;
