import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Stack, Typography } from '@mui/material';
import { useAuthData } from '@/contexts/AuthProvider';
import { TModalsContext } from '@/contexts/ModalsProvider/types';

import { combineStyles } from '@/utils/styleUtils';

import { InfoBox } from '@/components/InfoBox';
import { Loader } from '@/components/Loader';
import { ReadonlyField } from '@/components/ReadonlyField';
import { SuccessMessage } from '@/components/successMessage/SuccessMessage';

import type { TAccount } from '@/types/profile';

import { GenericModal } from '../modal/GenericModal';
import { GenericModalClose } from '../modal/GenericModalClose';
import { styles } from './styles';
import { extractLocation } from './utils';

export const ProfileModal = ({ open, close }: TModalsContext) => {
  const { t } = useTranslation();
  const { authContextData } = useAuthData();
  const [error, setError] = useState<string | null>(null);
  const { firstName = '', lastName = '', email = '', userId = '', retailer } = authContextData;
  const companyAddress = retailer?.address;
  const [profile, setProfile] = useState({
    phone: retailer?.phone1 || '',
    firstName,
    lastName,
    email,
    userId,
  });
  const [businessInfo, setBusinessInfo] = useState({
    secondaryAddress: '',
    authorizedRepresentativeFlag: false,
    tncVersion: 'PARTNER_V2',
    accountId: retailer?.retailerId || '',
    businessLegalName: retailer?.name || '',
    streetAddress: `${companyAddress.streetNumber} ${companyAddress.streetName}`,
    city: companyAddress?.city || '',
    state: companyAddress?.state || '',
    country: companyAddress?.country || '',
    zip: companyAddress?.postcode || '',
  });

  const [message, setMessage] = useState<string | null>(null);
  const changeEmail = () => {
    open('profileMenu.changeEmail', {
      onSuccess: (email) => {
        setProfile({ ...profile, email });
        setMessage(
          t('profileModal.changed', {
            label: t('profileModal.labels.email'),
          }),
        );
      },
    });
  };

  const changeBusinessAddress = () => {
    open('profileMenu.changeBusinessAddress', {
      initialValues: businessInfo,
      onSuccess: (data) => {
        setMessage(
          t('profileModal.changed', {
            label: t('profileModal.labels.businessAddress'),
          }),
        );
        setBusinessInfo((prev) => ({ ...prev, ...data }));
      },
    });
  };

  const changePassword = () => {
    open('profileMenu.changePassword', {
      onSuccess: () =>
        setMessage(
          t('profileModal.changed', {
            label: t('profileModal.labels.password'),
          }),
        ),
    });
  };
  const request = {
    loading: false,
  };
  return (
    <GenericModal sx={styles.modal}>
      <GenericModalClose onClick={() => close('profileMenu.profile')} />
      <Box sx={styles.container}>
        <SuccessMessage message={message} dismiss={() => setMessage(null)} />
        <Typography variant="h5" textAlign="center" sx={styles.title}>
          {t('profileModal.title')}
        </Typography>

        <Box position="relative">
          <Loader position="absolute" active={request.loading} />
          <Box sx={styles.columns}>
            <Box sx={styles.column}>
              <Typography variant="h6" sx={styles.subtitle}>
                {t('profileModal.personalDetails')}
              </Typography>
              <ReadonlyField
                label="profileModal.labels.firstName"
                value={profile.firstName}
                sx={styles.field}
              />
              <ReadonlyField
                label="profileModal.labels.lastName"
                value={profile.lastName}
                sx={styles.field}
              />
              <Stack direction="row">
                <ReadonlyField
                  label="profileModal.labels.email"
                  value={profile.email}
                  sx={styles.field}
                />
                <Button
                  variant="outlined"
                  sx={{ alignSelf: 'flex-start' }}
                  size="small"
                  onClick={changeEmail}
                >
                  {t('common.change')}
                </Button>
              </Stack>
              {/* <InfoBox color="blue" variant="body2" sx={styles.infoBox}>
                {t('profileModal.emailInfo')}
              </InfoBox> */}
              <ReadonlyField
                label="profileModal.labels.phone"
                value={profile.phone}
                sx={styles.field}
              />
              <Stack direction="row">
                <ReadonlyField label="profileModal.labels.password" value="•••••••••" />
                <Button
                  variant="outlined"
                  sx={{ alignSelf: 'flex-start' }}
                  size="small"
                  onClick={changePassword}
                >
                  {t('common.change')}
                </Button>
              </Stack>
            </Box>

            {businessInfo && (
              <Box sx={styles.column}>
                <Typography variant="h6" sx={styles.subtitle}>
                  {t('profileModal.partnerInformation')}
                </Typography>
                <ReadonlyField
                  label="profileModal.labels.businessLegalName"
                  value={businessInfo.businessLegalName}
                  sx={styles.field}
                />

                <Stack direction="row">
                  <ReadonlyField
                    label="profileModal.labels.businessAddress"
                    value={extractLocation({
                      streetAddress: businessInfo.streetAddress,
                      city: businessInfo.city,
                      state: businessInfo.state,
                      zip: businessInfo.zip,
                    })}
                    sx={combineStyles(styles.field, styles.businessAddress)}
                  />
                  {/* <Button
                    variant="outlined"
                    sx={{ alignSelf: 'flex-start' }}
                    size="small"
                    onClick={changeBusinessAddress}
                  >
                    {t('common.change')}
                  </Button> */}
                </Stack>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </GenericModal>
  );
};
