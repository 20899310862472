import { Box } from '@mui/material';

import { fromTemplate } from '@/utils/arrayUtils';

import { SimpleError } from '@/components/formik/error/SimpleError';

import type { TNewPasswordRules } from '@/validation/common/newPassword';

import type { TErrorProps } from './types';

type TLine<PKey = string> = {
  key: PKey;
  message?: string;
};

const LINES = 3;

const RULES: TLine<keyof TNewPasswordRules>[] = [
  {
    key: 'minLength',
    message: 'errors.newPassword.minLength',
  },
  {
    key: 'lowerUpper',
    message: 'errors.newPassword.lowerUpper',
  },
  {
    key: 'numberSymbol',
    message: 'errors.newPassword.numberSymbol',
  },
];

const PLACEHOLDERS = fromTemplate<TLine>(LINES, (i) => ({ key: `placeholder-${i}` }));

export const NewPasswordErrors = ({
  error: errors,
  errorsShown,
}: TErrorProps<TNewPasswordRules>) => {
  const lines = (RULES.filter(({ key }) => errors && !errors[key]) as TLine[])
    .concat(PLACEHOLDERS)
    .slice(0, LINES);

  return (
    <Box paddingY={0.5}>
      {lines.map(({ key, message }) => (
        <SimpleError key={key} error={message} withIcon errorsShown={errorsShown} />
      ))}
    </Box>
  );
};
