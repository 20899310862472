import { ChangeBusinessAddress } from '@/components/changeBusinessAddress/ChangeBusinessAddress';
import { ChangeEmail } from '@/components/changeEmail/ChangeEmail';
import { ChangePassword } from '@/components/changePassword/ChangePassword';
import { ConfirmCancel } from '@/components/confirmCancel/ConfirmCancel';
import { Faq } from '@/components/Faq/Faq';
import { PrivacyPolicy } from '@/components/privacyPolicy/PrivacyPolicy';
import { ProfileModal } from '@/components/profileModal/ProfileModal';
import { CreateTicket } from '@/components/support/createTicket/CreateTicket';
import { Support } from '@/components/support/Support';
import { SerialNumberDetailsModal } from '@/pages/Admin/CheckWarranty/SerialNumberDetailsModal';

export const COMPONENTS = {
  'cancel.confirm': ConfirmCancel,
  'profileMenu.faq': Faq,
  'profileMenu.profile': ProfileModal,
  'profileMenu.changePassword': ChangePassword,
  'profileMenu.changeEmail': ChangeEmail,
  'profileMenu.privacyPolicy': PrivacyPolicy,
  'profileMenu.changeBusinessAddress': ChangeBusinessAddress,
  'support.base': Support,
  'support.createTicket': CreateTicket,
  'checkWarranty.serialNumber': SerialNumberDetailsModal,
};
