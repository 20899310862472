import { useTranslation } from 'react-i18next';

import { Grid, Typography } from '@mui/material';

export const FallbackError = () => {
  const { t } = useTranslation();

  return (
    <Grid container direction="column" alignItems="center">
      <Typography variant="h4">😢</Typography>
      <Typography variant="h4">{t('errors.boundary.mainLine')}</Typography>
      <Typography variant="subtitle1">{t('errors.boundary.subLine')}</Typography>
    </Grid>
  );
};
