export const identityServer = '';

export const accountServer = '/accounts/profile-management/accounts';

export const publicProfileServer = '/profile';

export const dashboardServer = `${window.env.API_HOST}/api/aggregations/dashboard`;

export const loginServer = `${window.env.API_HOST}/api/public/token`;

export const updateProfileServer = `${window.env.API_HOST}/api/profile`;

export const supportServer = `${window.env.API_HOST}/api/tickets`;

export const warrantyServer = `${window.env.HYPERIAN_HOST}`;
