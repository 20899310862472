import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { noop } from '@altirllc/shared/utils/functionalUtils';

import type { TModalsContext } from '@/contexts/ModalsProvider/types';

import { handleError } from '@/utils/errorUtils';

import { GenericModal } from '@/components/modal/GenericModal';
import { GenericModalClose } from '@/components/modal/GenericModalClose';
import { SubmitError } from '@/components/submitError/SubmitError';

import { BusinessAddressForm } from './BusinessAddressForm';
import { styles } from './ChangeBusinessAddress.styles';
import { TLocation } from './types';
import { updateProfileDetails } from '@/services/profile';
import { useAuthData } from '@/contexts/AuthProvider';

type TChangeBusinessAddressProps = TModalsContext & {
  onSuccess: (data: TLocation) => void;
  initialValues?: TLocation;
};

export const ChangeBusinessAddress = ({
  close,
  initialValues,
  onSuccess,
}: TChangeBusinessAddressProps) => {
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();
  const { authContextData, updateUserData } = useAuthData();
  const { email, retailer } = authContextData;
  const closeModal = () => close('profileMenu.changeBusinessAddress');
  const { address: existingAddress } = retailer;

  const onSubmit = async (data: TLocation) => {
    try {
      const response = await updateProfileDetails({
        email,
        companyAddress: {
          ...existingAddress,
          streetName: data.streetAddress,
          city: data.city,
          state: data.state,
        },
      });
      const {
        retailer: { address },
      } = response.data;
      closeModal();
      onSuccess(data);
    } catch (err) {
      setError(`${t('errors.unknown')}\n${t('errors.tryAgain')}`);
      handleError(err, 'UNHANDLED_RESPONSE');
    }
  };

  return (
    <GenericModal sx={styles.modal}>
      <GenericModalClose onClick={closeModal} />

      <SubmitError
        error={error}
        dismiss={() => setError(null)}
        sx={{ top: 0, padding: 1, marginTop: 4 }}
      />

      <BusinessAddressForm
        initialValues={initialValues}
        handleSubmit={onSubmit}
        closeModal={closeModal}
      />
    </GenericModal>
  );
};
