import { Dispatch, SetStateAction, SyntheticEvent } from 'react';

import { Tab, Tabs as MuiTabs } from '@mui/material';

import { styles } from './Tabs.styles';
import { TTab, TTabVariant } from './types';

type TTabsProps<T> = {
  tabs: TTab<T>[];
  selectedTab: T;
  setSelectedTab: Dispatch<SetStateAction<T>>;
};

// TODO make universal tab component
export const Tabs = <T extends string>({ tabs, selectedTab, setSelectedTab }: TTabsProps<T>) => {
  const handleChange = (_: SyntheticEvent<Element, Event>, value: TTabVariant<T>) => {
    setSelectedTab(value);
  };

  return (
    <MuiTabs sx={styles.tabs} value={selectedTab} onChange={handleChange}>
      {tabs.map(({ tabTitle, key }) => (
        <Tab sx={{ textTransform: 'none' }} key={key} value={key} label={tabTitle} />
      ))}
    </MuiTabs>
  );
};
