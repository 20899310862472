import type { CSSProperties } from 'react';

import { styled } from '@mui/material/styles';

export const GenericModalFooter = styled('div', {
  name: 'GenericModalFooter',
  shouldForwardProp: (prop: string) => !['sx', 'squareButtons', 'placeContent'].includes(prop),
})<{
  squareButtons?: boolean;
  placeContent?: CSSProperties['placeContent'];
}>(({ squareButtons, placeContent, theme }) => ({
  position: 'sticky',
  bottom: 0,
  display: 'grid',
  alignItems: 'center',
  gridAutoFlow: 'column',
  columnGap: theme.spacing(2),
  padding: theme.spacing(2.5, 6),
  backgroundColor: theme.palette.background.paper,
  placeContent,

  '&:before': {
    content: '""',
    position: 'absolute',
    bottom: '100%',
    left: 0,
    right: 0,
    height: 32,
    pointerEvents: 'none',
    background:
      'radial-gradient(50% 100% at 50% 100%, rgba(0, 0, 0, 0.08) 0%, rgba(217, 217, 217, 0) 100%)',
  },

  ...(!squareButtons && {
    '& button': {
      borderRadius: '22px',
    },
  }),
}));
