import { getLocalStorageData } from '@/utils/localStorageUtils';

import { TGetDashboard } from '@/types/dashboard';

import { dashboardService } from '@/api/public';

export const getDashboardData: TGetDashboard = () => {
  const tokenString = getLocalStorageData('partner-web-token') || '';
  const tokenDetails = JSON.parse(tokenString);
  const { accessToken } = tokenDetails;
  return dashboardService.get('', {
    headers: {
      contentType: 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
