import { useTranslation } from 'react-i18next';
import { useRef, useEffect } from 'react';

import { Box, Grid, Typography, ImageList, ImageListItem } from '@mui/material';

import { SimpleCell } from '@/components/table/bodyCells/SimpleCell';
import { HeadCell } from '@/components/table/headCells/HeadCell';
import { Table } from '@/components/table/Table';
import { SimpleWrapperCell } from '@/components/table/bodyCells/SimpleWrapperCell';
import { Loader } from '@/components/Loader';

import type { extractTicketVM } from '../utils';
import { styles } from './TicketsTable.styles';

type TTicketViewModel = ReturnType<typeof extractTicketVM>;

type TTicketsTableProps = {
  tickets: TTicketViewModel[];
  showLoader?: boolean;
  textIfNoTickets?: string;
  triggerOnReachingBottom?: () => void;
};

export const TicketsTable = ({
  showLoader = false,
  tickets,
  textIfNoTickets,
  triggerOnReachingBottom,
}: TTicketsTableProps) => {
  const { t } = useTranslation();

  const targetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          if (triggerOnReachingBottom) {
            triggerOnReachingBottom();
          }
        }
      });
    });

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [tickets]);

  const headings = {
    plantId: `${t('support.list.plantId')}`,
    productName: t('support.list.productName'),
    unitSerialNumber: `${t('support.list.unitSerialNumber')}`,
    issueDate: t('support.list.issueDate'),
    subject: `${t('support.list.subject')}`,
    status: `${t('support.list.status')}`,
  };

  return (
    <Grid sx={styles.content}>
      <Box sx={styles.grid}>
        <Loader active={showLoader} position="absolute" />
        <Table
          data={tickets}
          textIfNoTickets={textIfNoTickets}
          collapsable
          headCells={() => [
            <HeadCell key="plantId" value={{ label: headings.plantId }} />,
            <HeadCell key="productName" value={{ label: headings.productName }} />,
            <HeadCell key="unitSerialNumber" value={{ label: headings.unitSerialNumber }} />,
            <HeadCell key="issueDate" value={{ label: headings.issueDate }} />,
            <HeadCell key="subject" value={{ label: headings.subject }} />,
            <HeadCell key="status" value={{ label: headings.status }} />,
            <HeadCell key="mocked" value={{ label: '' }} />,
          ]}
          bodyCells={(vm) => [
            <SimpleCell key="plantId" value={vm.plantId} typographyProps={{ fontSize: 16 }} />,
            <SimpleCell
              key="productName"
              value={vm.productName}
              typographyProps={{ fontSize: 16 }}
            />,
            <SimpleCell
              key="unitSerialNumber"
              value={vm.unitSerialNumber}
              typographyProps={{ fontSize: 16 }}
            />,
            <SimpleCell key="issueDate" value={vm.issueDate} typographyProps={{ fontSize: 16 }} />,
            <SimpleCell key="subject" value={vm.subject} typographyProps={{ fontSize: 16 }} />,
            <SimpleCell key="status" value={vm.status} typographyProps={{ fontSize: 16 }} />,
          ]}
          bodyCollapsableCells={(vm) => [
            <SimpleWrapperCell key="plantId" colSpan={7} style={{ height: 'auto' }}>
              <Typography
                style={{
                  color: '#6E768A',
                  fontSize: '12px',
                  lineHeight: '16px',
                  textAlign: 'left',
                }}
              >
                Description
              </Typography>
              <Typography
                style={{
                  fontSize: '16px',
                  textAlign: 'left',
                }}
              >
                {vm.description}
              </Typography>
            </SimpleWrapperCell>,
          ]}
          stickyHeader
        />
        {/* to trigger fetching of new data */}
        <Box ref={targetRef} style={{ height: '20px' }}></Box>
      </Box>
    </Grid>
  );
};
