import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { FormControl, FormHelperText, InputProps, TextField, TextFieldProps } from '@mui/material';
import { useField, useFormikContext } from 'formik';

import type { Override } from '@/types/common';

import { SimpleError } from './error/SimpleError';
import type { TErrorProps } from './error/types';

export type TFormikTextAreaProps<PError> = Override<
  TextFieldProps,
  {
    name: string;
    label: string;
    margin?: InputProps['margin'];
    'data-testid'?: string;
    HelperText?: (props: TErrorProps<PError>) => JSX.Element | null;
  }
>;

export const FormikTextArea = <PError extends unknown>({
  name,
  margin,
  label: labelKey,
  'data-testid': testId,
  HelperText = SimpleError as (props: TErrorProps<PError>) => JSX.Element,
  ...props
}: TFormikTextAreaProps<PError>) => {
  const [field, meta] = useField(name);
  const inputRef = useRef<HTMLInputElement>(null);
  const { submitCount } = useFormikContext();
  const { t } = useTranslation();

  const label = t(labelKey);
  const errorsShown = Boolean(meta.error) && submitCount > 0;

  return (
    <FormControl margin={margin}>
      <TextField
        name={name}
        label={label}
        inputRef={inputRef}
        value={field.value ?? ''}
        onChange={field.onChange}
        onBlur={field.onBlur}
        error={errorsShown}
        multiline
        inputProps={{
          'data-testid': testId,
        }}
        {...props}
      />

      <FormHelperText component="div">
        <HelperText
          error={meta.error as PError | undefined}
          errorsShown={errorsShown}
          label={label}
        />
      </FormHelperText>
    </FormControl>
  );
};
