import { SvgIcon, SvgIconProps } from '@mui/material';

export const EyeIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 22 18" {...props}>
    <path
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.75 9C1.75 7.98496 2.39346 6.16305 3.91524 4.58273C5.4072 3.03338 7.71554 1.75 11 1.75C14.2845 1.75 16.5928 3.03338 18.0848 4.58273C19.6065 6.16305 20.25 7.98496 20.25 9C20.25 10.015 19.6065 11.837 18.0848 13.4173C16.5928 14.9666 14.2845 16.25 11 16.25C7.71554 16.25 5.4072 14.9666 3.91524 13.4173C2.39346 11.837 1.75 10.015 1.75 9ZM11 0.25C7.28446 0.25 4.5928 1.71662 2.83476 3.54227C1.10654 5.33695 0.25 7.51504 0.25 9C0.25 10.485 1.10654 12.663 2.83476 14.4577C4.5928 16.2834 7.28446 17.75 11 17.75C14.7155 17.75 17.4072 16.2834 19.1652 14.4577C20.8935 12.663 21.75 10.485 21.75 9C21.75 7.51504 20.8935 5.33695 19.1652 3.54227C17.4072 1.71662 14.7155 0.25 11 0.25ZM8.74991 9C8.74991 7.75736 9.75727 6.75 10.9999 6.75C12.2425 6.75 13.2499 7.75736 13.2499 9C13.2499 10.2426 12.2425 11.25 10.9999 11.25C9.75727 11.25 8.74991 10.2426 8.74991 9ZM10.9999 5.25C8.92884 5.25 7.24991 6.92893 7.24991 9C7.24991 11.0711 8.92884 12.75 10.9999 12.75C13.071 12.75 14.7499 11.0711 14.7499 9C14.7499 6.92893 13.071 5.25 10.9999 5.25Z"
    />
  </SvgIcon>
);
