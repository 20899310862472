import { useTranslation } from 'react-i18next';

import { FormControl, FormControlProps, IconButton, Input, InputAdornment } from '@mui/material';

import { combineStyles } from '@/utils/styleUtils';

import { CrossCircle } from '../icons/CrossCircle';
import { Search } from '../icons/Search';
import { styles } from './SearchInput.styles';

type TSearchInputProps = {
  searchValue: string;
  setSearchValue: (value: string) => void;
};

export const SearchInput = ({
  searchValue,
  setSearchValue,
  sx,
  ...props
}: TSearchInputProps & FormControlProps) => {
  const { t } = useTranslation();

  return (
    <FormControl variant="outlined" sx={combineStyles(styles.inputWrapper, sx)} {...props}>
      <Input
        value={searchValue}
        onChange={({ target }) => {
          setSearchValue(target.value);
        }}
        disableUnderline
        placeholder={t('common.searchPlaceholder')}
        sx={{ height: 48 }}
        startAdornment={
          <InputAdornment position="start" sx={{ paddingLeft: 3, paddingRight: 1 }}>
            <Search sx={{ width: 20, color: 'primary.main' }} />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end" sx={{ px: 1 }}>
            {searchValue.length > 0 && (
              <IconButton onClick={() => setSearchValue('')} sx={{ p: 0 }}>
                <CrossCircle sx={{ fontSize: 20 }} />
              </IconButton>
            )}
          </InputAdornment>
        }
      />
    </FormControl>
  );
};
