import { PropsWithChildren, useState } from 'react';

import { Box, Collapse, Fade, Grid, Typography } from '@mui/material';

import { typedStyles } from '@/utils/styleUtils';

import { ChevronDown } from '@/components/icons/ChevronDown';

export type TCollapsableProps = PropsWithChildren<{
  title: string | JSX.Element;
  subTitle?: string | JSX.Element;
}>;

const styles = typedStyles({
  root: {
    backgroundColor: 'background.paper',
    borderRadius: 2,
    paddingBottom: 3,
  },

  header: {
    position: 'relative',
    zIndex: 1,
    padding: 3,
    paddingBottom: 1,
    alignItems: 'center',
    borderRadius: 'inherit',
    cursor: 'pointer',
  },

  titleWrapper: {
    position: 'relative',
    flex: 1,
  },

  subTitle: {
    position: 'absolute',
    top: '100%',
  },

  chevron: ({ transitions }) => ({
    transition: transitions.create('transform'),
  }),

  chevronUp: {
    transform: 'rotate(180deg)',
  },

  content: {
    position: 'relative',

    '&, .MuiCollapse-wrapper, .MuiCollapse-wrapperInner': {
      borderBottomLeftRadius: 'inherit',
      borderBottomRightRadius: 'inherit',
    },
  },
});

export const Collapsable = ({ title, subTitle, children }: TCollapsableProps) => {
  const [open, setOpen] = useState(false);
  return (
    <Box sx={styles.root}>
      <Grid onClick={() => setOpen(!open)} sx={styles.header}>
        <Box sx={styles.titleWrapper}>
          <Typography fontWeight={500}>{title}</Typography>

          {subTitle && (
            <Fade in={open}>
              <Typography variant="body2" color="text.secondary" sx={styles.subTitle}>
                {subTitle}
              </Typography>
            </Fade>
          )}
        </Box>

        <ChevronDown fontSize="small" sx={[styles.chevron, open && styles.chevronUp]} />
      </Grid>

      <Collapse in={open} sx={styles.content} unmountOnExit>
        {children}
      </Collapse>
    </Box>
  );
};
