import type { TSxStyles } from '@/types/common';

type TSxDef = Exclude<TSxStyles, ReadonlyArray<unknown>>;

type TSxArray = Extract<TSxStyles, ReadonlyArray<unknown>>;

export const typedStyles = <PKeys extends string>(styles: Record<PKeys, TSxDef>) => styles;

export const combineStyles = (...entries: Array<TSxStyles | false | undefined>) =>
  entries.reduce<TSxArray>((acc, styles) => {
    if (Array.isArray(styles)) {
      return [...acc, ...styles];
    }

    if (styles) {
      return [...acc, styles];
    }

    return acc;
  }, []);

export const doubleRadius = ({ borderRadius }: { borderRadius: string | number }) =>
  2 * Number(borderRadius);
