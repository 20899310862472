export const DATE_FORMAT = 'll';
export const TIME_FORMAT = 'LT z';
export const TIMESTAMP_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ[Z]';

export const CALENDAR_DATE_FORMAT = {
  sameDay: '[Today]',
  lastDay: '[Yesterday]',
  lastWeek: 'dddd, MMM D',
  sameElse: 'dddd, MMM D',
};

export const WEEKDAYS = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
] as const;

export const TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
