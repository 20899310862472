export const colors = {
  brandBlack: '#14151A',
  brandBlue: '#006AFF',
  bgBlue: '#EBF0F4',

  systemBlue: '#409CFF',
  systemGreen: '#34C759',
  systemDarkGreen: '#248A3D',
  systemOrange: '#FF9500',
  systemRed: '#FF3B30',
  systemDarkRed: '#FF453A',

  pinkRed: '#F9444C',

  systemIndigo: '#5856D6',

  // monochrome scale
  black: '#000000',
  almostBlack: '#22272F',
  darkestGrey: '#323642',
  darkerGrey: '#50576B',
  darkGrey: '#6E768A',
  mediumGrey2: '#ABB2C2',
  mediumGrey: '#E1E5ED',
  lightGrey: '#EDEFF5',
  lighterGrey: '#F5F6FA',
  lightestGrey: '#FAFAFC',
  white: '#FFFFFF',
} as const;

export const contrast = [
  colors.black,
  colors.darkestGrey,
  colors.darkerGrey,
  colors.darkGrey,
  colors.mediumGrey2,
  colors.mediumGrey,
  colors.lightGrey,
  colors.lighterGrey,
  colors.lightestGrey,
  colors.white,
];

export type TThemeColor = typeof colors[keyof typeof colors];
