import type { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, ButtonProps, Tooltip } from '@mui/material';
import { useFormikContext } from 'formik';

import { combineStyles } from '@/utils/styleUtils';
import { CircularProgress } from '@altirllc/shared/storybook/stories/CircularProgress.stories';

const PROGRESS_SIZE: Record<ButtonProps['size'] & string, number> = {
  large: 36,
  medium: 28,
  small: 20,
};

export const FormikSubmit = ({ children, sx, ...props }: ButtonProps) => {
  const { t } = useTranslation();
  const { isSubmitting, isValid, submitCount, setFormikState } = useFormikContext();

  const highlightErrors: MouseEventHandler = (event) => {
    const form = event.currentTarget.closest('form');

    setFormikState((state) => ({ ...state, submitCount: submitCount + 1 }));

    setTimeout(() => {
      form?.querySelector<HTMLInputElement>('.Mui-error input.MuiInput-input')?.focus();
    });
  };

  const disabledTooltip = !isValid ? t('errors.form.highlight') : '';
  const disabledOnClick = !isValid ? highlightErrors : undefined;

  return (
    <Tooltip placement="top" title={disabledTooltip} arrow>
      <Box onClick={disabledOnClick} sx={combineStyles({ cursor: 'pointer' }, sx)}>
        <Button type="submit" disabled={isSubmitting || !isValid} {...props} fullWidth>
          {children}
          {isSubmitting && <CircularProgress size={PROGRESS_SIZE[props.size || 'medium']} />}
        </Button>
      </Box>
    </Tooltip>
  );
};
