import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GenericModal = styled(Paper, { name: 'GenericModal' })({
  position: 'absolute',
  top: '50%',
  left: '50%',
  overflow: 'hidden',
  transform: 'translate(-50%, -50%)',
  borderRadius: 24,
  outline: 0,
});
