import { SvgIcon, SvgIconProps } from '@mui/material';

import type { TSxStyles } from '@/types/common';

export const CheckBoxChecked = ({
  checkColor,
  ...props
}: SvgIconProps & { checkColor: string }) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <rect x="0.5" y="0.5" width="23" height="23" rx="5.5" fill="currentColor" />
    <path d="M5.25 11.25L9.75 15.75L19.5 6.75" stroke={checkColor} strokeWidth="2" fill="none" />
  </SvgIcon>
);

export const storyCell: TSxStyles = {
  '& path': ({ palette }) => ({
    stroke: palette.custom.contrast[9],
  }),
};
