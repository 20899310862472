import { lighten } from '@mui/material';

import { typedStyles } from '@/utils/styleUtils';

import { colors } from '@/themesConfig/colors';

export const styles = typedStyles({
  modal: {
    display: 'flex',
    flexDirection: 'column',
    width: 'min(564px, 80%)',
    height: 'min(628px, 80%)',
    padding: 3,
  },

  dropZone: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    padding: 2,
    marginTop: 0,
    borderRadius: 3,
    border: ({ palette }) => `1px dashed ${palette.divider}`,
    marginBottom: 3,
  },

  dropZoneOverlay: {
    position: 'absolute',
    top: -1,
    left: -1,
    right: -1,
    bottom: -1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: lighten(colors.brandBlue, 0.9),
    border: `1px dashed ${colors.brandBlue}`,
    borderRadius: 'inherit',
  },

  imagePlaceholder: {
    marginBottom: 2,
    fontSize: 120,
    color: 'text.disabled',
  },

  selectedImage: {
    position: 'relative',
    marginBottom: 2,

    '& > img': {
      maxWidth: 120,
      maxHeight: 120,
      verticalAlign: 'top',
    },
  },

  clearImage: {
    position: 'absolute',
    top: -17,
    right: -17,
  },

  footer: {
    '--buttonMinWidth': '100px',

    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 1,
    gap: 2,
  },
});
