import { useContext, useState, useEffect } from 'react';
import { SupportContext } from './SupportContext';
import { TProviderProps } from '../types';
import { ETicketStatus } from '@/config/support';
import { TClosedTickets, TOpenTickets, TSupportTicketNewWithPlantDetails } from '@/types/support';
import { destructAPIResponse } from '@/components/support/utils';
import { getSupportTickets, fetchOpenOrClosedTickets } from '@/services/support';
import { stringToStatusEnum } from '@/config/support';
import { useAuthData } from '../AuthProvider';

export const initialState = {
  tickets: [
    {
      channel: '',
      commentCount: '',
      contactId: '',
      contact: {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
      },
      createdBy: '',
      createdTime: '', // date logged
      customerResponseTime: '',
      departmentId: '',
      department: {
        id: '',
        name: '',
      },
      description: '',
      entitySkills: [''],
      id: '',
      isArchived: false,
      isEscalated: false,
      isOverDue: false,
      isSpam: false,
      layoutId: '',
      modifiedBy: '', // last updated by
      modifiedTime: '', // last updated
      secondaryContacts: [''],
      sharedDepartments: [''],
      status: ETicketStatus.CLOSED,
      statusType: '',
      subject: '',
      threadCount: 0,
      ticketNumber: '',
      webUrl: '',
      cf: {
        cf_plant_id: '',
        cf_product_sn: '',
        cf_issue_date: '',
        cf_country: '',
      },
      customFields: {
        'Plant ID': '',
        'Issue date': '',
        'Unit SN': '',
        Country: '',
      },
      childTicketCount: '',
      isRead: false,
      plantModelId: 0,
      shortName: '',
      longName: '',
      numberOfBatteries: 0,
    },
  ],
};

export const SupportProvider = ({ children }: TProviderProps) => {
  const [supportDataState, setSupportDataState] = useState(initialState);
  const { authContextData } = useAuthData();
  const { authenticated } = authContextData;
  const [openTicketsData, setOpenTicketsData] = useState<TOpenTickets>({
    ...initialState,
    totalTicketsOnBackend: null,
    loading: false,
  });
  const [closedTicketsData, setClosedTicketsData] = useState<TClosedTickets>({
    ...initialState,
    totalTicketsOnBackend: null,
    loading: false,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const triggerFetchOpenOrClosedTickets = async (status: 'open' | 'closed') => {
    if (status === 'open') {
      setOpenTicketsData((initial) => ({ ...initial, loading: true }));
    } else {
      setClosedTicketsData((initial) => ({ ...initial, loading: true }));
    }
    try {
      const response = await fetchOpenOrClosedTickets({
        status,
        from:
          status === 'open'
            ? openTicketsData.tickets.length === 1
              ? 0
              : openTicketsData.tickets.length
            : closedTicketsData.tickets.length === 1
            ? 0
            : closedTicketsData.tickets.length,
        limit: 15,
      });
      if (status === 'open') {
        appenToOpenTickets(response.data.data);
        setOpenTicketsData((initial) => ({
          ...initial,
          loading: false,
          totalTicketsOnBackend: response.data.count,
        }));
      } else {
        appenToClosedTickets(response.data.data);
        setClosedTicketsData((initial) => ({
          ...initial,
          loading: false,
          totalTicketsOnBackend: response.data.count,
        }));
      }
    } catch (error) {
      setError(true);
      if (status === 'open') {
        setOpenTicketsData((initial) => ({ ...initial, loading: false }));
        appenToOpenTickets([]);
      } else {
        setClosedTicketsData((initial) => ({ ...initial, loading: false }));
        appenToClosedTickets([]);
      }
    }
  };

  useEffect(() => {
    if (authenticated) {
      triggerFetchOpenOrClosedTickets('open');
      triggerFetchOpenOrClosedTickets('closed');
    }
  }, [authenticated]);

  const appenToOpenTickets = (data: TSupportTicketNewWithPlantDetails[]) => {
    const refactoredTickets = data.map((eachTicket) => destructAPIResponse(eachTicket));
    setOpenTicketsData((initial) => {
      const newOpenTickets = refactoredTickets.map((ticket) => {
        return {
          ...ticket,
          status: stringToStatusEnum(ticket.status),
        };
      });
      const finalTickets =
        initial.tickets.length === 1 || initial.tickets.length === initial.totalTicketsOnBackend
          ? newOpenTickets
          : initial.tickets.concat(newOpenTickets);
      return {
        tickets: finalTickets,
        totalTicketsOnBackend: initial.totalTicketsOnBackend,
        loading: false,
      };
    });
  };

  const appenToClosedTickets = (data: TSupportTicketNewWithPlantDetails[]) => {
    const refactoredTickets = data.map((eachTicket) => destructAPIResponse(eachTicket));
    setClosedTicketsData((initial) => {
      const newClosedTickets = refactoredTickets.map((ticket) => {
        return {
          ...ticket,
          status: stringToStatusEnum(ticket.status),
        };
      });
      const finalTickets =
        initial.tickets.length === 1 || initial.tickets.length === initial.totalTicketsOnBackend
          ? newClosedTickets
          : initial.tickets.concat(newClosedTickets);
      return {
        tickets: finalTickets,
        totalTicketsOnBackend: initial.totalTicketsOnBackend,
        loading: false,
      };
    });
  };

  const appendTicketOnSuccess = (data: TSupportTicketNewWithPlantDetails) => {
    const refactoredTicket = destructAPIResponse(data);
    setSupportDataState((initial) => ({
      tickets: [
        { ...refactoredTicket, status: stringToStatusEnum(refactoredTicket.status) },
        ...initial.tickets,
      ],
    }));
  };

  const onGetSupportSuccess = (data: TSupportTicketNewWithPlantDetails[]) => {
    const refactoredTickets = data.map((eachTicket) => destructAPIResponse(eachTicket));
    setSupportDataState({
      tickets: refactoredTickets.map((ticket) => {
        return {
          ...ticket,
          status: stringToStatusEnum(ticket.status),
        };
      }),
    });
  };

  return (
    <SupportContext.Provider
      value={{
        supportDataState,
        onGetSupportSuccess,
        errorWhileFetchingData: error,
        loadingData: loading,
        appendTicketOnSuccess,
        openTicketsList: openTicketsData,
        closedTicketsList: closedTicketsData,
        appendOpenTickets: (data) => {},
        appendClosedTickets: (data) => {},
        triggerFetchOpenOrClosedTickets,
      }}
    >
      {children}
    </SupportContext.Provider>
  );
};

export const useSupportContext = () => useContext(SupportContext);
