import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { noop } from '@altirllc/shared/utils/functionalUtils';

import type { TModalsContext } from '@/contexts/ModalsProvider/types';

import { handleError, isResponseError } from '@/utils/errorUtils';

import { GenericModal } from '@/components/modal/GenericModal';
import { GenericModalClose } from '@/components/modal/GenericModalClose';
import { SubmitError } from '@/components/submitError/SubmitError';

import { styles } from './ChangePassword.styles';
import { ChangePasswordForm } from './ChangePasswordForm';
import { TChangePasswordFormData } from './types';
import { updateProfileDetails } from '@/services/profile';

type TChangePasswordProps = TModalsContext & {
  onSuccess: () => void;
};
export const ChangePassword = ({ close, onSuccess }: TChangePasswordProps) => {
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  const closeModal = () => {
    close('profileMenu.changePassword');
  };

  const onSubmit = async ({ newPassword, currentPassword }: TChangePasswordFormData) => {
    try {
      const response = await updateProfileDetails({
        oldPassword: currentPassword,
        newPassword: newPassword,
      });
      closeModal();
      onSuccess();
    } catch (err) {
      if (isResponseError(err, 401)) {
        setError(t('changePassword.invalidPassword'));
      } else if (isResponseError(err, 400)) {
        setError(t('forgotPassword.cantMatchCurrent'));
      } else {
        setError(`${t('errors.unknown')}\n${t('errors.tryAgain')}`);
        handleError(err, 'UNHANDLED_RESPONSE');
      }
    }
  };

  return (
    <GenericModal sx={styles.modal}>
      <GenericModalClose onClick={() => closeModal()} />
      <SubmitError
        error={error}
        dismiss={() => setError(null)}
        sx={{ top: 0, padding: 1, marginTop: 4 }}
      />

      <ChangePasswordForm handleSubmit={onSubmit} closeModal={() => closeModal()} />
    </GenericModal>
  );
};
