import dayjs, { Dayjs } from 'dayjs';
import * as Yup from 'yup';

import { parseTime } from '@/utils/dateUtils';

const requiredTime: Yup.BaseSchema<Dayjs | null, unknown, Dayjs> = Yup.mixed()
  .transform((value) => {
    if (value && !dayjs.isDayjs(value)) {
      return parseTime(value);
    }

    return value;
  })
  .required('errors.form.required');

export const requiredTimeRange = Yup.object({
  startTime: requiredTime,
  endTime: requiredTime.test(
    'endBeforeStart',
    'errors.form.endTimeBeforeStart',
    (value, { parent }) => !value || value >= parent.startTime,
  ),
}).required();
