import { createContext } from 'react';

import { TSignInResponseContent } from '@/types/auth';
import { TAddress, TUserData } from '@/types/profile';

export type TAuthContext = {
  authContextData: TUserData;
  onLoginSuccess: (loginResponse: TSignInResponseContent) => void;
  logoutHandler: () => void;
  updateUserData: (newData: Partial<TUserData>) => void;
  setUserData: (data: Partial<TUserData>) => void;
};

export const initialAuthState = {
  userId: 0,
  type: '',
  username: '',
  email: '',
  firstName: '',
  lastName: '',
  isActive: true,
  createdAt: '',
  updatedAt: '',
  lastActivityAt: '',
  retailer: {
    retailerId: 1,
    name: '',
    contactName: '',
    phone1: '',
    phone2: '',
    email: '',
    abn: '',
    address: {
      unitNumber: 0,
      streetNumber: 0,
      streetName: '',
      city: '',
      postcode: '',
      state: '',
      country: '',
    },
    isActive: true,
    website: '',
    retailerCode: '',
    createdAt: '',
  },
  role: {
    roleId: 1,
    title: '',
    roleIsForRetailer: false,
    permissionDisabled: false,
  },
  isSuperAdmin: true,
  timezone: {
    timezoneId: 0,
    timezone: '',
  },
  isDeleted: false,
  passwordChangedAt: '',
  partnerPortalEnabled: true,
  authenticated: false,
  idTokenExpiry: 0,
  mobileNumber: '',
  accessTokenExpiry: 0,
  userDataLoaded: false,
};

export const AuthContext = createContext<TAuthContext>({
  authContextData: initialAuthState,
  onLoginSuccess: (loginResponse: TSignInResponseContent) => {},
  logoutHandler: () => {},
  updateUserData: ({}) => {},
  setUserData: (data: Partial<TUserData>) => {},
});
