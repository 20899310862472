import { getLocalStorageData } from '@/utils/localStorageUtils';

import { TRequest } from '@/types/common';
import {
  TGetSupportTickets,
  TCreateTicketResponse,
  TCreateTicketContent,
  TEditTicketContent,
  TGetTicketsResponse,
  TSearchPayload,
  TGetProductIds,
  TOpenOrClosedTicketsPayload,
  TGetComments,
  TCreateCommentContent,
  TComment,
  TGetThreadDetails,
  TFetchThreadProps,
} from '@/types/support';

import { supportService } from '@/api/public';

export const getSupportTickets: TGetSupportTickets = (filters: any) => {
  const { page = 0, size = 24, filter } = filters;
  const productLineValues =
    filter?.productLine?.map((option: { value: string }) => option.value) ?? [];
  const statusValues = filter?.status ?? [];
  const tokenString = getLocalStorageData('partner-web-token') || '';
  const tokenDetails = JSON.parse(tokenString);
  const { accessToken } = tokenDetails;

  let query = `/search?from=${page * size}&limit=${size}`;
  if (productLineValues.length > 0) {
    query = query + `&productId=${productLineValues?.[0] ?? ''}`;
  }
  if (statusValues.length == 1) {
    if (statusValues?.[0] === 'CLOSED') {
      query = query + `&status=close`;
    }
    if (statusValues?.[0] === 'OPEN') {
      query = query + `&status=open`;
    }
  }

  return supportService.get(query, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      accept: 'application/json',
    },
  });
};

export const fetchOpenOrClosedTickets: TRequest<TGetTicketsResponse, TOpenOrClosedTicketsPayload> =
  (detailsPayload: TOpenOrClosedTicketsPayload) => {
    const { status = 'open', from = 0, limit = 10 } = detailsPayload;
    const tokenString = getLocalStorageData('partner-web-token') || '';
    const tokenDetails = JSON.parse(tokenString);
    const { accessToken } = tokenDetails;
    return supportService.get(`/search?status=${status}&from=${from}&limit=${limit}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        accept: 'application/json',
      },
    });
  };

export const searchSupportTickets: TRequest<TGetTicketsResponse, TSearchPayload> = (
  searchPayload: TSearchPayload,
) => {
  const { searchText = '', from = '' } = searchPayload;
  const tokenString = getLocalStorageData('partner-web-token') || '';
  const tokenDetails = JSON.parse(tokenString);
  const { accessToken } = tokenDetails;
  return supportService.get(`/search?_all=${searchText}&from=${from}&limit=${10}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      accept: 'application/json',
    },
  });
};

export const createTicket: TRequest<TCreateTicketResponse, TCreateTicketContent> = (
  data: TCreateTicketContent,
) => {
  const tokenString = getLocalStorageData('partner-web-token') || '';
  const tokenDetails = JSON.parse(tokenString);
  const { accessToken } = tokenDetails;
  return supportService.post('', data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const editTicket: TRequest<TCreateTicketResponse, TEditTicketContent> = ({
  data,
  ticketId,
}: TEditTicketContent) => {
  const tokenString = getLocalStorageData('partner-web-token') || '';
  const tokenDetails = JSON.parse(tokenString);
  const { accessToken } = tokenDetails;
  return supportService.patch(`/${ticketId}`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const updateTicket: TRequest<TCreateTicketResponse, TCreateTicketContent> = (
  data: TCreateTicketContent,
) => {
  const tokenString = getLocalStorageData('partner-web-token') || '';
  const tokenDetails = JSON.parse(tokenString);
  const { accessToken } = tokenDetails;
  return supportService.patch('', data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const fetchProductModels: TGetProductIds = (plantId: string) => {
  const tokenString = getLocalStorageData('partner-web-token') || '';
  const tokenDetails = JSON.parse(tokenString);
  const { accessToken } = tokenDetails;
  return supportService.get(`/productModel/${Number(plantId)}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      accept: 'application/json',
    },
  });
};

export const fetchComments: TGetComments = (ticketId: string) => {
  const tokenString = getLocalStorageData('partner-web-token') || '';
  const tokenDetails = JSON.parse(tokenString);
  const { accessToken } = tokenDetails;
  return supportService.get(`/${ticketId}/conversations?limit=200`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      accept: 'application/json',
    },
  });
};

export const createComment: TRequest<TComment, TCreateCommentContent> = (
  data: TCreateCommentContent,
) => {
  const tokenString = getLocalStorageData('partner-web-token') || '';
  const tokenDetails = JSON.parse(tokenString);
  const { accessToken } = tokenDetails;
  return supportService.post('/comments', data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const fetchThreadDetails: TGetThreadDetails = ({
  ticketId,
  threadId,
}: TFetchThreadProps) => {
  const tokenString = getLocalStorageData('partner-web-token') || '';
  const tokenDetails = JSON.parse(tokenString);
  const { accessToken } = tokenDetails;
  return supportService.get(`/${ticketId}/threads/${threadId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      accept: 'application/json',
    },
  });
};
