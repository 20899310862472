import { getLocalStorageData } from '@/utils/localStorageUtils';

import { TRequest } from '@/types/common';
import { TUpdateProfilePayload, TUpdateProfileResponse, TGetProfileDetails } from '@/types/profile';

import { updateProfileService } from '@/api/public';

export const updateProfileDetails: TRequest<
  TUpdateProfileResponse,
  Partial<TUpdateProfilePayload>
> = (data: Partial<TUpdateProfilePayload>) => {
  const tokenString = getLocalStorageData('partner-web-token') || '';
  const tokenDetails = JSON.parse(tokenString);
  const { accessToken } = tokenDetails;
  return updateProfileService.patch('', data, {
    headers: {
      contentType: 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getProfileDetails: TGetProfileDetails = () => {
  const tokenString = getLocalStorageData('partner-web-token') || '';
  const tokenDetails = JSON.parse(tokenString);
  const { accessToken } = tokenDetails;
  return updateProfileService.get('', {
    headers: {
      contentType: 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
