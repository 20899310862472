import { TOption } from '@/types/common';

export enum ETicketCategory {
  PARTNER_PORTAL = 'RMA_PARTNER_PORTAL',
  DEAD_BMS = 'DEAD_BMS',
  BACKPUP_ISSUES = 'BACKPUP_ISSUES',
}

export enum ELanguageOption {
  ENGLISH = 'English',
  POLISH = 'Polish',
  GERMAN = 'German',
}

export enum ECountryOption {
  AUSTRALIA = 'Australia',
  NEW_ZEALAND = 'New Zealand',
  POLAND = 'Poland',
  GERMANY = 'Germany',
  USA = 'USA',
}

export enum ETicketSubject {
  ARCHIVE = 'ARCHIVE',
  CONNECTIVITY_ISSUES = 'CONNECTIVITY_ISSUES',
  DEAD_COMPONENTS = 'DEAD_COMPONENTS',
  GENERAL_ISSUES = 'GENERAL_ISSUES',
}

export enum ETicketStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export const ticketStatusToDisplayKey = (status: ETicketStatus) => {
  switch (status) {
    case ETicketStatus.IN_PROGRESS:
      return 'support.statuses.inProgress';
    case ETicketStatus.OPEN:
      return 'support.statuses.open';
    case ETicketStatus.CLOSED:
      return 'support.statuses.closed';
  }
};

export const stringToStatusEnum = (status: string) => {
  switch (status) {
    case 'In Progress':
      return ETicketStatus.IN_PROGRESS;
    case 'Open':
      return ETicketStatus.OPEN;
    case 'Closed':
      return ETicketStatus.CLOSED;
    default:
      return ETicketStatus.CLOSED;
  }
};

export const subjectToDisplayKey = (category: ETicketSubject) => {
  switch (category) {
    case ETicketSubject.ARCHIVE:
      return 'support.subjects.archive';

    case ETicketSubject.CONNECTIVITY_ISSUES:
      return 'support.subjects.connectivityIssues';

    case ETicketSubject.DEAD_COMPONENTS:
      return 'support.subjects.deadComponents';

    case ETicketSubject.GENERAL_ISSUES:
      return 'support.subjects.generalIssues';
  }
};

export const categoryToDisplayKey = (category: ETicketCategory) => {
  switch (category) {
    case ETicketCategory.PARTNER_PORTAL:
      return 'support.categories.partnerPortal';

    case ETicketCategory.DEAD_BMS:
      return 'support.categories.deadBMS';

    case ETicketCategory.BACKPUP_ISSUES:
      return 'support.categories.backupIssues';
  }
};

export const CATEGORIES_OPTIONS: { category: string; items: TOption<ETicketCategory>[] }[] = [
  {
    category: subjectToDisplayKey(ETicketSubject.ARCHIVE),
    items: [
      {
        label: categoryToDisplayKey(ETicketCategory.PARTNER_PORTAL),
        value: ETicketCategory.PARTNER_PORTAL,
      },
    ],
  },
  {
    category: subjectToDisplayKey(ETicketSubject.CONNECTIVITY_ISSUES),
    items: [],
  },
  {
    category: subjectToDisplayKey(ETicketSubject.DEAD_COMPONENTS),
    items: [
      {
        label: categoryToDisplayKey(ETicketCategory.DEAD_BMS),
        value: ETicketCategory.DEAD_BMS,
      },
    ],
  },
  {
    category: subjectToDisplayKey(ETicketSubject.GENERAL_ISSUES),
    items: [
      {
        label: categoryToDisplayKey(ETicketCategory.BACKPUP_ISSUES),
        value: ETicketCategory.BACKPUP_ISSUES,
      },
    ],
  },
];

export const PREFERRED_LANGUAGE_OPTIONS: {
  label: ELanguageOption;
  value: ELanguageOption;
  disabled?: boolean;
}[] = [
  {
    label: ELanguageOption.ENGLISH,
    value: ELanguageOption.ENGLISH,
  },
  {
    label: ELanguageOption.POLISH,
    value: ELanguageOption.POLISH,
  },
  {
    label: ELanguageOption.GERMAN,
    value: ELanguageOption.GERMAN,
  },
];

export const COUNTRY_OPTIONS: {
  label: ECountryOption;
  value: string;
  disabled?: boolean;
}[] = [
  {
    label: ECountryOption.AUSTRALIA,
    value: 'AU',
  },
  {
    label: ECountryOption.NEW_ZEALAND,
    value: 'NZ',
  },
  {
    label: ECountryOption.POLAND,
    value: 'PL',
  },
  {
    label: ECountryOption.GERMANY,
    value: 'DE',
  },
  {
    label: ECountryOption.USA,
    value: 'US',
  },
];
