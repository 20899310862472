import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Typography, Box, Link as MuiLink, List, ListItem, ListItemText } from '@mui/material';

import type { TModalsContext } from '@/contexts/ModalsProvider/types';

import { GenericModal } from '@/components/modal/GenericModal';

import { GenericModalClose } from '../modal/GenericModalClose';
import { links, mayCollectItems } from './config';
import { styles } from './PrivacyPolicy.styles';

export const PrivacyPolicy = ({ close }: TModalsContext) => {
  const { t } = useTranslation();

  return (
    <GenericModal sx={styles.root}>
      <GenericModalClose
        onClick={() => {
          close('profileMenu.privacyPolicy');
        }}
      />
      <Typography variant="h5" textAlign="center">
        {t('privacyPolicy.title')}
      </Typography>
      <Box>
        <Typography marginTop={6} marginBottom={8} whiteSpace="pre-line">
          {t('privacyPolicy.description')}
        </Typography>

        <Typography variant="h5">{t('privacyPolicy.privacyFromOneDay.title')}</Typography>

        <Typography marginTop={3} marginBottom={4} whiteSpace="pre-line">
          {t('privacyPolicy.privacyFromOneDay.description')}
        </Typography>

        <Box>
          {links.map(({ link, title }) => (
            <MuiLink component={Link} key={title} sx={styles.link} to={link} target="_self">
              {t(title)}
            </MuiLink>
          ))}
        </Box>
      </Box>
      <Box marginTop={8} id="weMayCollect">
        <Typography variant="h5">{t('privacyPolicy.infoWeMayCollect.title')}</Typography>
        <Typography marginTop={3} whiteSpace="pre-line">
          {t('privacyPolicy.infoWeMayCollect.weMayCollect')}
        </Typography>
        <Box>
          <List
            sx={{
              listStyleType: 'disc',
              listStylePosition: 'inside',
              '& .MuiTypography-root.MuiTypography-body1': {
                display: 'inline',
              },
            }}
          >
            {mayCollectItems.map((title) => (
              <ListItem key={title}>
                <ListItemText primary={t(title)} sx={{ display: 'list-item' }} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </GenericModal>
  );
};
