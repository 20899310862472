import { colors, contrast } from '../colors';
import { createThemeMode } from './common';

export const darkTheme = createThemeMode(
  'dark',
  {
    primary: colors.white,
    secondary: colors.brandBlack,
    textPrimary: colors.lighterGrey,
    textSecondary: colors.mediumGrey,
    textDisabled: colors.mediumGrey2,
    textIcon: colors.lightestGrey,
    backgroundMain: colors.black,
    backgroundPaper: colors.brandBlack,
    backgroundGradient: `linear-gradient(225deg, ${colors.darkestGrey} 0%, ${colors.black} 100%)`,
    inputPlaceholder: colors.mediumGrey2,
    menuItemFocused: colors.darkestGrey,
    divider: colors.darkestGrey,
    contrast: [...contrast].reverse(),
  },
  {
    2: '0px 2px 8px rgba(255, 255, 255, 0.08)',
    3: '0px 2px 8px rgba(255, 255, 255, 0.16)',
  },
);
