import { TFunction, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Typography, Link as MuiLink } from '@mui/material';

import { styles } from './Faq.styles';
import { TFaqTab } from './types';

export const getFaqData = (t: TFunction): TFaqTab[] => [
  {
    key: 'general',
    tabTitle: t('faq.general.title'),
    items: [
      {
        question: t('faq.general.questions.whereManufactoredTitle'),
        answer: t('faq.general.questions.whereManufactoredAnswer'),
      },
      {
        question: t('faq.general.questions.whatRebates'),
        answer: t('faq.general.questions.whatRebatesAnswer'),
      },
      {
        question: t('faq.general.questions.prismaticCell'),
        answer: t('faq.general.questions.prismaticCellAnswer'),
      },
      {
        question: t('faq.general.questions.dispoteSystem'),
        answer: t('faq.general.questions.dispoteSystemAnswer'),
      },
      {
        question: t('faq.general.questions.howActivate'),
        answer: t('faq.general.questions.howActivateAnswer'),
      },
    ],
  },
  {
    key: 'hardware',
    tabTitle: t('faq.hardware.title'),
    items: [
      {
        question: t('faq.hardware.questions.coolingMethod'),
        answer: t('faq.hardware.questions.coolingMethodAnswer'),
      },
      {
        question: t('faq.hardware.questions.asNaturalConvection'),
        answer: t('faq.hardware.questions.asNaturalConvectionAnswer'),
      },
      {
        question: t('faq.hardware.questions.cablesLong'),
        answer: t('faq.hardware.questions.cablesLongAnswerAnswer'),
      },
      {
        question: t('faq.hardware.questions.revenueGrade'),
        answer: t('faq.hardware.questions.revenueGradeAnswer'),
      },
      {
        question: t('faq.hardware.questions.canCTbeExtended'),
        answer: t('faq.hardware.questions.canCTbeExtendedAnswer'),
      },
      {
        question: t('faq.hardware.questions.storeHyperian'),
        answer: t('faq.hardware.questions.storeHyperianAnswer'),
      },
      {
        question: t('faq.hardware.questions.impactWarranty'),
        answer: t('faq.hardware.questions.impactWarrantyAnswer'),
      },
      {
        question: t('faq.hardware.questions.magnaFlap'),
        answer: t('faq.hardware.questions.magnaFlapAnswer'),
      },
      {
        question: t('faq.hardware.questions.roundtripEfficiency'),
        answer: t('faq.hardware.questions.roundtripEfficiencyAnswer'),
      },
      {
        question: t('faq.hardware.questions.mountOption'),
        answer: t('faq.hardware.questions.mountOptionAnswer'),
      },
      {
        question: t('faq.hardware.questions.installWithoutBackup'),
        answer: t('faq.hardware.questions.installWithoutBackupAnswer'),
      },
      {
        question: t('faq.hardware.questions.connectivityOptions'),
        answer: t('faq.hardware.questions.connectivityOptionsAnswer'),
      },
      {
        question: t('faq.hardware.questions.can3GUsed'),
        answer: t('faq.hardware.questions.can3GUsedAnswer'),
      },
      {
        question: t('faq.hardware.questions.simCardSize'),
        answer: t('faq.hardware.questions.simCardSizeAnswer'),
      },
      {
        question: t('faq.hardware.questions.securityFeatures'),
        answer: t('faq.hardware.questions.securityFeaturesAnswer'),
      },
      {
        question: t('faq.hardware.questions.sideInstallation'),
        answer: t('faq.hardware.questions.sideInstallationAnswer'),
      },
      {
        question: t('faq.hardware.questions.subUnitsInstallation'),
        answer: t('faq.hardware.questions.subUnitsInstallationAnswer'),
      },
      {
        question: t('faq.hardware.questions.circuits'),
        answer: t('faq.hardware.questions.circuitsAnswer'),
      },
    ],
  },

  {
    key: 'software',
    tabTitle: t('faq.software.title'),
    items: [
      {
        question: t('faq.software.questions.blackout'),
        answer: t('faq.software.questions.blackoutAnswer'),
      },
      {
        question: t('faq.software.questions.fleetSystem'),
        answer: t('faq.software.questions.fleetSystemAnswer'),
      },
      {
        question: t('faq.software.questions.getFleetSystem'),
        answer: t('faq.software.questions.getFleetSystemAnswer'),
      },
      {
        question: t('faq.software.questions.setPrevileges'),
        answer: t('faq.software.questions.setPrevilegesAnswer'),
      },

      {
        question: t('faq.software.questions.connectThirdParty'),
        answer: t('faq.software.questions.connectThirdPartyAnswer'),
      },
      {
        question: t('faq.software.questions.loginWhenBatteryCommissioned'),
        answer: t('faq.software.questions.loginWhenBatteryCommissionedAnswer'),
      },
      {
        question: t('faq.software.questions.dataUsage'),
        answer: t('faq.software.questions.dataUsageAnswer'),
      },
      {
        question: t('faq.software.questions.navigationVideo'),
        answer: t('faq.software.questions.navigationVideoAnswer'),
      },
      {
        question: t('faq.software.questions.forgotPassword'),
        answer: t('faq.software.questions.forgotPasswordAnswer'),
      },
      {
        question: t('faq.software.questions.resetSettings'),
        answer: t('faq.software.questions.resetSettingsAnswer'),
      },
      {
        question: t('faq.software.questions.upgradesOccur'),
        answer: t('faq.software.questions.upgradesOccurAnswer'),
      },
      {
        question: t('faq.software.questions.identifyBlackout'),
        answer: t('faq.software.questions.identifyBlackoutAnswer'),
      },
    ],
  },
  {
    key: 'installation',
    tabTitle: t('faq.installation.title'),
    items: [
      {
        question: t('faq.installation.questions.nearOcean'),
        answer: t('faq.installation.questions.nearOceanAnswer'),
      },
      {
        question: t('faq.installation.questions.intallationTime'),
        answer: t('faq.installation.questions.intallationTimeAnswer'),
      },
      {
        question: t('faq.installation.questions.howCanBeInstalled'),
        answer: t('faq.installation.questions.howCanBeInstalledAnswer'),
      },
      {
        question: t('faq.installation.questions.canBeInstalledDirect'),
        answer: t('faq.installation.questions.canBeInstalledDirectAnswer'),
      },
    ],
  },
  {
    key: 'orders',
    tabTitle: t('faq.orders.title'),
    items: [
      {
        question: t('faq.orders.questions.placeOrder'),
        answer: t('faq.orders.questions.placeOrderAnswer'),
      },
      {
        question: t('faq.orders.questions.specificMOQ'),
        answer: t('faq.orders.questions.specificMOQAnswer'),
      },
      {
        question: t('faq.orders.questions.leadTimes'),
        answer: t('faq.orders.questions.leadTimesAnswer'),
      },
      {
        question: t('faq.orders.questions.hyperianUnits'),
        answer: t('faq.orders.questions.hyperianUnitsAnswer'),
      },
      {
        question: t('faq.orders.questions.orderIssue'),
        answer: (
          <Typography component="span" fontWeight={600}>
            <Trans i18nKey="faq.orders.questions.orderIssueAnswer">
              <MuiLink sx={styles.link} href="mailto:orders@hyperian.com" />
            </Trans>
          </Typography>
        ),
        answerKey: 'faq.orders.questions.orderIssueAnswer',
      },
    ],
  },
  {
    key: 'support',
    tabTitle: t('faq.support.title'),
    items: [
      {
        question: t('faq.support.questions.whatTechSupport'),
        answer: (
          <Typography component="span" fontWeight={600}>
            <Trans i18nKey="faq.support.questions.whatTechSupportAnswer">
              <MuiLink sx={styles.link} href="mailto:support@hyperian.com" />
            </Trans>
          </Typography>
        ),
        answerKey: 'faq.support.questions.whatTechSupportAnswer',
      },
      {
        question: t('faq.support.questions.techIssue'),
        answer: (
          <Typography component="span" fontWeight={600}>
            <Trans i18nKey="faq.support.questions.techIssueAnswer">
              <MuiLink sx={styles.link} href="mailto:support@hyperian.com" />
            </Trans>
          </Typography>
        ),
        answerKey: 'faq.support.questions.techIssueAnswer',
      },
      {
        question: t('faq.support.questions.howWarrantyWork'),
        answer: t('faq.support.questions.howWarrantyWorkAnswer'),
      },
      {
        question: t('faq.support.questions.installationGuide'),
        answer: (
          <Typography component="span" fontWeight={600}>
            <Trans i18nKey="faq.support.questions.installationGuideAnswer">
              <MuiLink component={Link} sx={styles.link} to="/resources" target="_blank" />
            </Trans>
          </Typography>
        ),
      },
      {
        question: t('faq.support.questions.uploadImage'),
        answer: (
          <Typography component="span" fontWeight={600}>
            <Trans i18nKey="faq.support.questions.uploadImageAnswer">
              <MuiLink component={Link} sx={styles.link} to="/resources" target="_blank" />
            </Trans>
          </Typography>
        ),
        answerKey: 'faq.support.questions.uploadImageAnswer',
      },
      {
        question: t('faq.support.questions.supportRouterUpgrade'),
        answer: t('faq.support.questions.supportRouterUpgradeAnswer'),
      },
      {
        question: t('faq.support.questions.supportCommunicationLost'),
        answer: t('faq.support.questions.supportCommunicationLostAnswer'),
      },
    ],
  },
];
