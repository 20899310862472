import { typedStyles } from '@/utils/styleUtils';

import { colors } from '@/themesConfig/colors';

export const styles = typedStyles({
  root: {
    width: 'min(80%, 884px)',
    height: '90%',
    paddingTop: 6,
    display: 'flex',
    flexDirection: 'column',
  },

  tabs: ({ palette }) => ({
    borderBottom: `1px solid ${palette.divider}`,
    '& .MuiTabs-flexContainer': {
      justifyContent: 'center',
    },
  }),

  inputWrapper: {
    backgroundColor: colors.lighterGrey,
    borderRadius: 6,
    marginTop: 3,
    marginBottom: 1,
    alignSelf: 'center',
    maxWidth: 'calc(100% - 144px)',
  },

  accordionWrapper: {
    height: 0,
    overflowY: 'auto',
    flexGrow: 1,
  },

  link: {
    color: colors.brandBlue,
    textDecorationColor: colors.brandBlue,
    fontWeight: 500,
  },
});
