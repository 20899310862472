import { useTranslation } from 'react-i18next';

import { TableCell, Typography } from '@mui/material';

import { noop } from '@altirllc/shared/utils/functionalUtils';

import { fromTemplate } from '@/utils/arrayUtils';

import type { IOnSort, TSort } from '@/components/filter/types';

import type { TBaseCellProps, THeading } from '../types';
import { HeadLabel } from './HeadLabel';

export type THeadCellProps = TBaseCellProps & {
  value: THeading | THeading[];
  onSort?: IOnSort;
  sort?: TSort;
};

export const HeadCell = ({ value, sort, onSort = noop, ...props }: THeadCellProps) => {
  const { t } = useTranslation();

  const values = Array.isArray(value) ? value : [value];

  const headings = values.map((heading, index) => (
    <HeadLabel
      key={heading.label}
      label={t(heading.label)}
      sortKey={heading.sortKey}
      leftIcon={index === 0 && values.length > 1}
      sorted={sort && sort.key === heading.sortKey && sort.direction}
      onSort={onSort}
    />
  ));

  const separators = fromTemplate(values.length - 1, (index) => (
    <Typography key={`separator-${index}`} variant="caption" color="text.secondary">
      {' | '}
    </Typography>
  ));

  const blocks = headings.reduce(
    (memo, heading, index) => [...memo, heading, separators[index]],
    [] as JSX.Element[],
  );

  return (
    <TableCell variant="head" {...props}>
      {blocks}
    </TableCell>
  );
};
