import axios from 'axios';

declare module 'axios' {
  export interface AxiosRequestConfig {
    toastError?: boolean;
  }
}

export const handleErrorInterceptor = (error: Error) => {
  if (axios.isAxiosError(error) && !axios.isCancel(error)) {
    // TODO. Handle error
  }

  return Promise.reject(error);
};
