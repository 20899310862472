import { IconButton, IconButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Cross } from '@/components/icons/Cross';

type TGenericModalCloseProps = Omit<IconButtonProps, 'onClick' | 'children'> & {
  onClick: IconButtonProps['onClick'];
};

const StyledClose = styled(IconButton, { name: 'GenericModalClose' })({
  position: 'absolute',
  top: 16,
  right: 16,
  zIndex: 1,
});

export const GenericModalClose = (props: TGenericModalCloseProps) => (
  <StyledClose {...props}>
    <Cross />
  </StyledClose>
);
