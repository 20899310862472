import { useEffect, useState } from 'react';

import { Alert, Snackbar, Typography } from '@mui/material';

import { combineStyles, typedStyles } from '@/utils/styleUtils';

import { TSxStyles } from '@/types/common';

type TSubmitErrorProps = {
  error: JSX.Element | string | null;
  dismiss: () => void;
  sx?: TSxStyles;
};

const styles = typedStyles({
  snackbar: {
    position: 'absolute',
    bottom: ['100%', '100%'],
    left: [0, '30%'],
    right: [0, 0],
    marginBottom: 2,
  },

  alert: {
    width: ' 100%',
    borderRadius: 8,

    '.MuiAlert-icon': {
      display: 'none',
    },

    '.MuiAlert-message': {
      flex: 1,
      marginLeft: '30px',
      textAlign: 'center',
    },

    '.MuiAlert-action': {
      padding: 0,
      margin: 0,
      alignSelf: 'center',
    },
  },
});

export const SubmitError = ({ sx, error: extError, dismiss }: TSubmitErrorProps) => {
  const [error, setError] = useState(extError);

  useEffect(() => {
    if (extError) {
      setError(extError);
    }
  }, [extError]);

  return (
    <Snackbar open={!!extError} onClose={dismiss} sx={combineStyles(styles.snackbar, sx)}>
      <Alert color="error" variant="filled" onClose={dismiss} sx={styles.alert} elevation={1}>
        <Typography whiteSpace="pre-line" fontWeight={500}>
          {error}
        </Typography>
      </Alert>
    </Snackbar>
  );
};
