import axios from 'axios';

import { correlationIdInterceptor } from '@altirllc/shared/utils/api/common/interceptors';
import { identity } from '@altirllc/shared/utils/functionalUtils';

import { handleErrorInterceptor } from '../common';
import {
  identityServer,
  publicProfileServer,
  loginServer,
  dashboardServer,
  updateProfileServer,
  supportServer,
  warrantyServer,
} from '../urls';

const apiConfig = {
  publicProfileService: {
    baseURL: publicProfileServer,
    headers: {},
    request: [correlationIdInterceptor],
    response: [handleErrorInterceptor],
  },

  identityService: {
    baseURL: identityServer,
    headers: {},
    request: [],
    response: [handleErrorInterceptor],
  },
  customersService: {
    baseURL: identityServer,
    headers: {},
    request: [],
    response: [handleErrorInterceptor],
  },
  loginService: {
    baseURL: loginServer,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    request: [],
    response: [handleErrorInterceptor],
  },
  dashboardService: {
    baseURL: dashboardServer,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    request: [],
    response: [handleErrorInterceptor],
  },
  updateProfileService: {
    baseURL: updateProfileServer,
    headers: {},
    request: [],
    response: [handleErrorInterceptor],
  },
  supportService: {
    baseURL: supportServer,
    headers: {},
    request: [],
    response: [handleErrorInterceptor],
  },
  warrantyService: {
    baseURL: warrantyServer,
    headers: {},
    request: [],
    response: [handleErrorInterceptor],
  },
};

const createPublicService = (service: keyof typeof apiConfig) => {
  const { baseURL, request, headers, response } = apiConfig[service];
  const instance = axios.create({ baseURL, headers });

  instance.interceptors.request.use(...request);
  response.forEach((interceptor) => instance.interceptors.response.use(identity, interceptor));
  return instance;
};
export const publicProfileService = createPublicService('publicProfileService');
export const identityService = createPublicService('identityService');
export const customerService = createPublicService('customersService');
export const loginService = createPublicService('loginService');
export const dashboardService = createPublicService('dashboardService');
export const updateProfileService = createPublicService('updateProfileService');
export const supportService = createPublicService('supportService');
export const warrantService = createPublicService('warrantyService');
