export const setLocalStorageData = ({
  keyName,
  tokenObject,
}: {
  keyName: string;
  tokenObject: Record<string, string | number>;
}): void => {
  localStorage?.setItem(keyName, JSON.stringify(tokenObject));
};

export const getLocalStorageData = (key: string) => {
  return localStorage?.getItem(key);
};

export const removeLocalStorageData = (key: string) => {
  localStorage?.removeItem(key);
};
