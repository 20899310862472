import { useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IconButton, TableRow as MuiTableRow, TableCell, TableRowProps } from '@mui/material';

type TTableRowProps<T> = TableRowProps & {
  collapsable?: boolean;
  collapsableItems?: JSX.Element[];
};

export const BodyTableRow = <T extends any[]>({
  collapsable,
  collapsableItems,
  children,
  ...props
}: TTableRowProps<T>) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <MuiTableRow {...props}>
        {children}
        {collapsable && (
          <TableCell align="right">
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
      </MuiTableRow>
      {collapsable && open && <>{collapsableItems}</>}
    </>
  );
};
