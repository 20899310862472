import { GenericModal } from '@/components/modal/GenericModal';
import { GenericModalClose } from '@/components/modal/GenericModalClose';
import type { TModalsContext } from '@/contexts/ModalsProvider/types';
import { styles } from './SerialNumberDetailsModal.styles';
import { Box, Typography, CardMedia, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import serialNumber1 from '@/assets/img/SerialNumber1.png';
import serialNumber2 from '@/assets/img/serialNumber2.png';

export const SerialNumberDetailsModal = ({ close }: TModalsContext) => {
  const { t } = useTranslation();

  const closeHandler = () => {
    close('checkWarranty.serialNumber');
  };

  return (
    <GenericModal sx={styles.modal}>
      <GenericModalClose onClick={closeHandler} />
      <Box sx={styles.header}>
        <Typography sx={styles.headingText}>{t('checkWarranty.findSerialNumber')}</Typography>
      </Box>
      <Box sx={styles.imageContainer}>
        <Box sx={styles.imageCard}>
          <CardMedia sx={styles.imageSize} component="img" image={serialNumber1} />
          <Typography variant="body1">{t('checkWarranty.imageOneDescription')}</Typography>
        </Box>
        <Box sx={styles.imageCard}>
          <CardMedia sx={styles.imageSize} component="img" image={serialNumber2} />
          <Typography variant="body1">{t('checkWarranty.imageTwoDescription')}</Typography>
        </Box>
      </Box>
      <Button sx={styles.button} onClick={closeHandler}>
        {t('checkWarranty.gotIt')}
      </Button>
    </GenericModal>
  );
};
