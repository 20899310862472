import { Component, ReactNode } from 'react';

import { FallbackError } from './FallbackError';
import { ErrorBoundaryProps, ErrorBoundaryState } from './types';

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidMount() {
    window.addEventListener('error', this.onUnhandledError);
    window.addEventListener('unhandledrejection', this.onUnhandledRejection);
  }

  componentDidCatch(error: Error): void {
    console.error(error, 'BOUNDARY_ERROR');
  }

  componentWillUnmount() {
    window.removeEventListener('error', this.onUnhandledError);
    window.removeEventListener('unhandledrejection', this.onUnhandledRejection);
  }

  onUnhandledError = ({ error }: ErrorEvent) => {
    console.warn(error, 'UNHANDLED_ERROR');
  };

  onUnhandledRejection = ({ reason }: PromiseRejectionEvent) => {
    console.warn(reason, 'UNHANDLED_REJECTION');
  };

  render(): ReactNode {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? <FallbackError /> : children;
  }
}
