import { useTranslation } from 'react-i18next';

import { Box, Button, Typography } from '@mui/material';
import { InferType } from 'yup';

import { noop } from '@altirllc/shared/utils/functionalUtils';

import { handleError, isResponseError } from '@/utils/errorUtils';

import { FormikPassword } from '@/components/formik/FormikPassword';
import { GenericModalFooter } from '@/components/modal/GenericModalFooter';

import { TOnSubmit } from '@/types/formTypes';

import { confirmPasswordSchema } from '@/validation/settings';

import { FormikForm } from '../formik/FormikForm';
import { FormikSubmit } from '../formik/FormikSubmit';
import { styles } from './ChangeEmail.styles';
import { STEPS } from './config';
import { updateProfileDetails } from '@/services/profile';
import { useAuthData } from '@/contexts/AuthProvider';

type TConfirmPasswordFormData = InferType<typeof confirmPasswordSchema>;

type TConfirmPasswordFormProps = {
  email: string;
  changeStep: (step: STEPS) => void;
  onError: (error: string) => void;
  closeModal: () => void;
  onSuccess: (email: string) => void;
};

const updateUserInfo = noop;

const validatePassword = noop;
export const ConfirmPasswordForm = ({
  email,
  changeStep,
  closeModal,
  onSuccess,
  onError,
}: TConfirmPasswordFormProps) => {
  const { t } = useTranslation();
  const { updateUserData } = useAuthData();

  const onSubmit: TOnSubmit<TConfirmPasswordFormData> = async (
    // eslint-disable-next-line @typescript-eslint/naming-convention
    { password },
  ) => {
    try {
      await validatePassword();
    } catch (error) {
      if (isResponseError(error, 422, 'SVC_006')) {
        onError(t('changeEmail.invalidPassword'));
      } else {
        onError(`${t('errors.unknown')}\n${t('errors.tryAgain')}`);
        handleError(error, 'UNHANDLED_RESPONSE');
      }
      return;
    }
    try {
      await updateProfileDetails({
        oldPassword: password,
        email: email,
      });
      updateUserData({ email });
      closeModal();
      onSuccess(email);
    } catch (error) {
      onError(`${error?.response?.data?.message}\n${t('errors.tryAgain')}`);
      handleError(error, 'UNHANDLED_RESPONSE');
    }
  };

  return (
    <FormikForm validationSchema={confirmPasswordSchema} onSubmit={onSubmit} sx={styles.form}>
      <Box sx={styles.fields}>
        <Box sx={styles.header}>
          <Typography variant="h5">{t('changeEmail.confirmPasswordTitle')}</Typography>
        </Box>

        <Typography marginBottom={4} color="text.secondary">
          {t('changeEmail.passwordDescription')}
        </Typography>
        <FormikPassword name="password" label="changeEmail.password" />
      </Box>

      <GenericModalFooter>
        <Button variant="outlined" onClick={() => changeStep(STEPS.ENTER_EMAILS)}>
          {t('common.back')}
        </Button>

        <FormikSubmit>{t('common.confirm')}</FormikSubmit>
      </GenericModalFooter>
    </FormikForm>
  );
};
