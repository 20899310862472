import { TableSortLabel, Typography } from '@mui/material';

import type { TSortDirection, TSortKey } from '@/components/filter/types';

import type { TSxStyles } from '@/types/common';

type THeadLabelProps = {
  label: string;
  sortKey: TSortKey | undefined;
  onSort: (sortKey: TSortKey) => void;
  sorted: TSortDirection | undefined | false;
  leftIcon: boolean;
};

const leftIconStyles: TSxStyles = {
  '& .MuiTableSortLabel-icon': {
    position: 'absolute',
    right: '100%',
  },
};

export const HeadLabel = ({ label, sortKey, onSort, sorted, leftIcon }: THeadLabelProps) => {
  if (!sortKey) {
    return (
      <Typography variant="caption" color="textSecondary" fontWeight={500} noWrap>
        {label}
      </Typography>
    );
  }

  return (
    <TableSortLabel
      active={!!sorted}
      direction={sorted || undefined}
      onClick={() => onSort(sortKey)}
      sx={[leftIcon && leftIconStyles]}
    >
      <Typography variant="caption" color="textSecondary" fontWeight={500} noWrap>
        {label}
      </Typography>
    </TableSortLabel>
  );
};
