import { useTranslation } from 'react-i18next';

import { Box, Button, Typography } from '@mui/material';

import { FormikForm } from '@/components/formik/FormikForm';
import { FormikSubmit } from '@/components/formik/FormikSubmit';
import { GenericModalFooter } from '@/components/modal/GenericModalFooter';

import type { TOnSubmit } from '@/types/formTypes';

import { locationSchema } from '@/validation/auth';

import { BusinessAddressFields } from '../businessAddressFields/BusinessAddressFields';
import { styles } from './ChangeBusinessAddress.styles';
import { TLocation } from './types';

type TBusinessAddressForm = {
  handleSubmit: TOnSubmit<TLocation>;
  closeModal: () => void;
  initialValues?: TLocation;
};
export const BusinessAddressForm = ({
  handleSubmit,
  closeModal,
  initialValues,
}: TBusinessAddressForm) => {
  const { t } = useTranslation();

  return (
    <FormikForm
      initialValues={initialValues}
      validationSchema={locationSchema}
      onSubmit={handleSubmit}
      sx={styles.form}
    >
      <Box sx={styles.fields}>
        <Box sx={styles.header}>
          <Typography variant="h5">{t('changeBusinessAddress.title')}</Typography>

          <Typography color="textSecondary" marginTop={2}>
            {t('changeBusinessAddress.subTitle')}
          </Typography>
        </Box>

        <BusinessAddressFields />
      </Box>

      <GenericModalFooter>
        <Button variant="outlined" onClick={closeModal}>
          {t('common.cancel')}
        </Button>

        <FormikSubmit>{t('common.save')}</FormikSubmit>
      </GenericModalFooter>
    </FormikForm>
  );
};
