import { TableCell, Typography, TypographyProps } from '@mui/material';

import type { TBaseCellProps } from '../types';

export type TSimpleCellProps = TBaseCellProps & {
  value: string;
  typographyProps?: TypographyProps;
};

export const SimpleCell = ({ value, typographyProps, children, ...props }: TSimpleCellProps) => {
  return (
    <TableCell {...props}>
      <Typography
        variant="body2"
        color={value ? 'text.primary' : 'text.disabled'}
        noWrap
        {...typographyProps}
      >
        {value || '—'}
        {children}
      </Typography>
    </TableCell>
  );
};
