import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { ThemeProvider } from '@/contexts/ThemeContextProvider';

import { AuthProvider } from '@/contexts/AuthProvider/AuthProvider';

import { DashboardProvider } from '@/contexts/DashboardProvider';

import { SupportProvider } from '@/contexts/SupportProvider';

import { ErrorBoundary } from '@/components/errors/ErrorBoundary';

import { App } from './App';
import { LOCAL } from './constants';

import './i18n';
import './scripts/dayjs';
import { ModalsProvider } from './contexts/ModalsProvider';

if (!LOCAL && 'serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js');
  });
}

ReactDOM.render(
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <AuthProvider>
      <DashboardProvider>
        <SupportProvider>
          <ThemeProvider>
            <CssBaseline />

            <Router>
              <ErrorBoundary>
                <ModalsProvider>
                  <App />
                </ModalsProvider>
              </ErrorBoundary>
            </Router>
          </ThemeProvider>
        </SupportProvider>
      </DashboardProvider>
    </AuthProvider>
  </LocalizationProvider>,
  document.getElementById('root'),
);

window.hash = process.env.GIT_COMMIT_HASH;
