import { useTranslation } from 'react-i18next';

import {
  Autocomplete,
  AutocompleteProps,
  FormControl,
  FormHelperText,
  TextField,
} from '@mui/material';
import { useField, useFormikContext } from 'formik';

import { SimpleError } from '@/components/formik/error/SimpleError';

import { ChevronDown } from '../icons/ChevronDown';

export type TFormikAutocompleteProps = Omit<
  AutocompleteProps<string, false, true, false>,
  'renderInput'
> & {
  name: string;
  label: string;
  'data-testid'?: string;
};

export const FormikAutocomplete = ({
  name,
  label: labelKey,
  'data-testid': testId,
  ...props
}: TFormikAutocompleteProps) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  const { submitCount } = useFormikContext();
  const { t } = useTranslation();

  const label = t(labelKey);
  const errorsShown = !!meta.error && submitCount > 0;
  const noOptionsText = t('errors.form.noOptions');

  return (
    <FormControl>
      <Autocomplete
        value={field.value}
        onChange={(event: unknown, newValue: string | null) => {
          setValue(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="none"
            variant="standard"
            name={name}
            label={label}
            error={errorsShown}
            inputProps={{
              ...params.inputProps,
              'data-testid': testId,
            }}
          />
        )}
        popupIcon={<ChevronDown />}
        noOptionsText={noOptionsText}
        disableClearable
        {...props}
      />

      <FormHelperText component="div">
        <SimpleError error={meta.error} errorsShown={errorsShown} label={label} />
      </FormHelperText>
    </FormControl>
  );
};
