import { Box, CircularProgress, Fade } from '@mui/material';

import { typedStyles } from '@/utils/styleUtils';

const styles = typedStyles({
  common: () =>
    // { palette }
    ({
      display: 'grid',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      placeItems: 'center',
      // backgroundColor: palette.background.default,
      // backgroundImage: palette.custom.backgroundGradient,
      borderRadius: 'inherit',
    }),

  absolute: {
    zIndex: 10,
    position: 'absolute',
  },

  fixed: {
    zIndex: 10e5,
    position: 'fixed',
  },
});

export const Loader = ({
  active,
  position = 'fixed',
}: {
  active: boolean;
  position?: 'fixed' | 'absolute';
}) => {
  return (
    <Fade in={active} appear={false} unmountOnExit>
      <Box sx={[styles.common, styles[position]]}>
        <CircularProgress size={60} thickness={5} />
      </Box>
    </Fade>
  );
};
