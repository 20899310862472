import { useState } from 'react';

import type { TModalsContext } from '@/contexts/ModalsProvider/types';

import { GenericModal } from '@/components/modal/GenericModal';
import { GenericModalClose } from '@/components/modal/GenericModalClose';
import { SubmitError } from '@/components/submitError/SubmitError';

import { styles } from './ChangeEmail.styles';
import { STEPS } from './config';
import { ConfirmPasswordForm } from './ConfirmPasswordForm';
import { EnterEmailsForm } from './EnterEmailsForm';

type TChangeEmail = TModalsContext & {
  onSuccess: (email: string) => void;
};

export const ChangeEmail = ({ close, onSuccess }: TChangeEmail) => {
  const [error, setError] = useState<string | null>(null);
  const [email, setEmail] = useState('');
  const [currentStep, setCurrentStep] = useState(STEPS.ENTER_EMAILS);
  const changeStep = (step: STEPS) => {
    setCurrentStep(step);
  };
  const closeModal = () => {
    close('profileMenu.changeEmail');
  };

  const renderForm = () => {
    switch (currentStep) {
      case STEPS.ENTER_EMAILS:
        return (
          <EnterEmailsForm
            changeStep={changeStep}
            initialEmail={email}
            setEmail={setEmail}
            onError={setError}
            closeModal={closeModal}
          />
        );

      case STEPS.CONFIRM_PASSWORD:
        return (
          <ConfirmPasswordForm
            email={email}
            changeStep={changeStep}
            closeModal={closeModal}
            onSuccess={onSuccess}
            onError={setError}
          />
        );
    }
  };

  return (
    <GenericModal sx={styles.modal}>
      <GenericModalClose onClick={closeModal} />

      <SubmitError
        error={error}
        dismiss={() => setError(null)}
        sx={{ top: 0, padding: 1, marginTop: 4 }}
      />

      {renderForm()}
    </GenericModal>
  );
};
