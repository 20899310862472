import * as Yup from 'yup';

const checkPasswordRules = (password: string) => ({
  minLength: /[^\s]{8}/.test(password),
  lowerUpper: /\p{Ll}/u.test(password) && /\p{Lu}/u.test(password),
  numberSymbol: /\d/.test(password) && /[^\w\s]/.test(password),
});

export type TNewPasswordRules = ReturnType<typeof checkPasswordRules>;

export const newPassword = Yup.string()
  .ensure()
  .test('rules', (value, { createError }) => {
    const rules = checkPasswordRules(value);
    const allPassed = Object.values(rules).every(Boolean);

    if (!allPassed) {
      return createError({
        message: rules,
      });
    }

    return true;
  });
