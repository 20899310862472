import { useContext, useState, useEffect } from 'react';

import type { TProviderProps } from '../types';
import { AuthContext, initialAuthState } from './AuthContext';
import { TSignInResponseContent, TAccessToken, TIdToken } from '@/types/auth';
import { TUserData } from '@/types/profile';
import jwtDecode from 'jwt-decode';
import {
  setLocalStorageData,
  getLocalStorageData,
  removeLocalStorageData,
} from '@/utils/localStorageUtils';

export const AuthProvider = ({ children }: TProviderProps) => {
  const [authContextData, setAuthContextData] = useState(initialAuthState);

  const onLoginSuccess = (loginResponse: TSignInResponseContent): void => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { access_token = '', id_token = '', token_type } = loginResponse;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { exp: access_token_expiry = 0 }: TAccessToken = jwtDecode(access_token);

    // eslint-disable-next-line @typescript-eslint/naming-convention
    setLocalStorageData({
      keyName: 'partner-web-token',
      tokenObject: {
        accessToken: access_token,
        idToken: id_token,
        expiry: access_token_expiry,
        tokenType: token_type,
      },
    });
    setAuthContextData((initial) => ({
      ...initial,
      authenticated: true,
      accessTokenExpiry: access_token_expiry,
    }));
  };

  const checkLocalStorageOnLaunch = () => {
    const tokenString = getLocalStorageData('partner-web-token');
    if (tokenString) {
      const tokenDetails = JSON.parse(tokenString);
      const { accessToken, idToken, expiry, tokenType } = tokenDetails;
      const now = new Date().getTime() / 1000;
      if (now > expiry) {
        removeLocalStorageData('partner-web-token');
      } else {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        onLoginSuccess({
          access_token: accessToken,
          id_token: idToken,
          expires_in: expiry,
          token_type: tokenType,
        });
      }
    }
  };

  const setUserData = (userData: Partial<TUserData>) => {
    setAuthContextData((initial) => ({
      ...initial,
      ...userData,
      authenticated: true,
      userDataLoaded: true,
    }));
  };

  useEffect(() => {
    checkLocalStorageOnLaunch();
  }, []);

  const logoutHandler = (): void => {
    setAuthContextData(initialAuthState);
    removeLocalStorageData('partner-web-token');
  };

  const updateUserData = (newData: Partial<TUserData>) => {
    setAuthContextData((initial) => ({ ...initial, ...newData }));
  };

  return (
    <AuthContext.Provider
      value={{ authContextData, onLoginSuccess, logoutHandler, updateUserData, setUserData }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthData = () => useContext(AuthContext);
