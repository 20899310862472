import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button } from '@mui/material';

import { PREFERRED_LANGUAGE_OPTIONS, COUNTRY_OPTIONS } from '@/config/support';

import { FormikForm } from '@/components/formik/FormikForm';
import { FormikSubmit } from '@/components/formik/FormikSubmit';
import { GenericModalFooter } from '@/components/modal/GenericModalFooter';
import { UploadFiles } from '@/components/uploadFiles/UploadFiles';

import type { TOnSubmit } from '@/types/formTypes';

import { createTicketSchema } from '@/validation/support';

import { FormikText } from '../../formik/FormikText';
import { FormikTextArea } from '../../formik/FormikTextArea';
import { TCreateTicket } from '../types';
import { styles } from './CreateTicket.styles';
import { FormikSelect } from '@/components/formik/FormikSelect';
import { FormikDate } from '@/components/formik/FormikDate';
import { fetchProductModels } from '@/services/support';
import { returnProductOptions, TOptions, TProduct } from '../utils';
import { ZohoProduct } from '@/types/support';
import { Loader } from '@/components/Loader';
import { handleError } from '@/utils/errorUtils';
import { SubmitError } from '@/components/submitError/SubmitError';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

type TCreateTicketForm = {
  handleSubmit: TOnSubmit<TCreateTicket>;
  closeModal: () => void;
  filesList: File[];
  setFilesList: Dispatch<SetStateAction<File[]>>;
  errorOnCreateTicket?: string | null;
  isEditTicketForm?: boolean;
  defaultValues?: any;
};

export const CreateTicketForm = ({
  handleSubmit,
  closeModal,
  filesList,
  setFilesList,
  isEditTicketForm = false,
  defaultValues = {},
}: TCreateTicketForm) => {
  const { t } = useTranslation();

  const [plantIdExists, setPlantIdExists] = useState(false);
  const [productNameOptions, setProductNameOptions] = useState<TOptions[]>([] as TOptions[]);
  const [productDetails, setProductDetails] = useState<ZohoProduct>({} as ZohoProduct);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [unitSerialNumber, setUnitSerialNumber] = useState('');

  const triggerOnChange = async (updatedValue: string) => {
    try {
      setLoading(true);
      const response = await fetchProductModels(updatedValue);
      const {
        plantExists = false,
        allZohoProducts = [],
        matchedZohoProduct = {
          longName: '',
          plantModelId: 0,
          shortName: '',
          zohoProductId: '',
        },
        unitSn = '',
      } = response.data;
      setLoading(false);
      setPlantIdExists(plantExists);
      setUnitSerialNumber(unitSn);
      if (!plantExists) {
        setError(`${t('support.createTicket.plantIdError')}`);
        const productsToDisplay = returnProductOptions(allZohoProducts);
        setProductNameOptions(productsToDisplay);
      } else {
        setError(null);
        setProductDetails(matchedZohoProduct);
        const productsToDisplay = returnProductOptions(allZohoProducts);
        setProductNameOptions(productsToDisplay);
      }
    } catch (error) {
      setLoading(false);
      setError(`${t('support.createTicket.plantIdError')}`);
      handleError(error, 'UNHANDLED_RESPONSE');
    }
  };

  useEffect(() => {
    if (isEditTicketForm) {
      triggerOnChange(defaultValues?.plantId ?? '');
    }
  }, [isEditTicketForm]);

  return (
    <Box sx={styles.formWrapper}>
      <Loader active={loading} position="absolute" />
      <SubmitError
        error={error}
        dismiss={() => setError(null)}
        sx={{ top: 0, padding: 1, marginTop: 4 }}
      />
      <FormikForm
        initialValues={
          isEditTicketForm
            ? {
                plantId: defaultValues.plantId,
                productName: defaultValues.productName,
                unitSerialNumber: defaultValues.unitSerialNumber,
                subject: defaultValues.subject,
                issueDescription: defaultValues.issueDescription,
                country: defaultValues.country,
                language: defaultValues.language,
              }
            : {}
        }
        validationSchema={createTicketSchema}
        onSubmit={(values, formikHelpers) => {
          handleSubmit(
            {
              ...values,
              productName:
                plantIdExists && Object.keys(productDetails).length !== 0
                  ? productDetails.zohoProductId
                  : values.productName,
            },
            formikHelpers,
          );
        }}
        sx={styles.form}
      >
        <Box sx={styles.fields}>
          <Box sx={styles.fieldsRow}>
            <FormikText
              variant="outlined"
              labelVariant="filled"
              name="plantId"
              label="support.createTicket.plantId"
              data-testid="plantId"
              triggerOnBlur={triggerOnChange}
            />
            {Object.keys(productDetails).length !== 0 ? (
              <FormikText
                variant="outlined"
                labelVariant="filled"
                name="productName"
                label="support.createTicket.productName"
                data-testid="productName"
                defaultValue={productDetails.longName}
                disableInput={true}
              />
            ) : (
              <FormikSelect
                variant="outlined"
                name="productName"
                label="support.createTicket.productName"
                placeholder={t('support.createTicket.productName')}
                options={productNameOptions}
                data-testid="productName"
                disableSelect={plantIdExists ? false : true}
              />
            )}
          </Box>
          <Box sx={styles.fieldsRow}>
            <FormikText
              variant="outlined"
              labelVariant="filled"
              name="unitSerialNumber"
              label="support.createTicket.unitSerialNumber"
              data-testid="unitSerialNumber"
              defaultValue={unitSerialNumber}
            />

            <FormikDate
              name="issueDate"
              label="support.createTicket.issueDate"
              data-testid="issueDate"
              defaultValue={defaultValues.issueDate}
            />
          </Box>

          <FormikText
            variant="outlined"
            labelVariant="filled"
            name="subject"
            label="support.createTicket.subject"
            data-testid="subject"
          />

          <FormikTextArea
            label="support.createTicket.describeTheIssue"
            name="issueDescription"
            rows={3}
          />

          <UploadFiles filesList={filesList} setFilesList={setFilesList} />

          <Box sx={styles.fieldsRow}>
            <FormikSelect
              variant="outlined"
              name="language"
              label="support.createTicket.preferredLanguage"
              placeholder={t('support.createTicket.preferredLanguage')}
              options={PREFERRED_LANGUAGE_OPTIONS}
              data-testid="preferredLanguage"
              defaultValue={defaultValues?.language ?? ''}
            />
            <FormikSelect
              variant="outlined"
              name="country"
              label="support.createTicket.country"
              placeholder={t('support.createTicket.country')}
              options={COUNTRY_OPTIONS}
              data-testid="country"
              defaultValue={defaultValues?.country ?? ''}
            />
          </Box>
        </Box>

        <GenericModalFooter sx={styles.footer}>
          <Button variant="outlined" onClick={closeModal}>
            {t('common.discard')}
          </Button>

          <FormikSubmit>{t('common.submit')}</FormikSubmit>
        </GenericModalFooter>
      </FormikForm>
    </Box>
  );
};
