export const links = [
  {
    title: 'privacyPolicy.privacyFromOneDay.links.collectInformation',
    link: '#weMayCollect',
  },
  {
    title: 'privacyPolicy.privacyFromOneDay.links.useInformation',
    link: '#',
  },
  {
    title: 'privacyPolicy.privacyFromOneDay.links.sharingInformation',
    link: '#',
  },
  {
    title: 'privacyPolicy.privacyFromOneDay.links.choiceAndTransparency',
    link: '#',
  },
  {
    title: 'privacyPolicy.privacyFromOneDay.links.privacyQuestions',
    link: '#',
  },
];

export const mayCollectItems = [
  'privacyPolicy.infoWeMayCollect.items.aboutYou',
  'privacyPolicy.infoWeMayCollect.items.aboutEnergy',
];
