import { useTranslation } from 'react-i18next';

import {
  FormControl,
  FormControlProps,
  InputLabel,
  Typography,
  TypographyProps,
} from '@mui/material';

type TReadonlyFieldProps = FormControlProps & {
  value: JSX.Element | string | undefined;
  label?: string;
  slotProps?: {
    Typography?: TypographyProps;
  };
};

export const ReadonlyField = ({ label, value, slotProps, ...props }: TReadonlyFieldProps) => {
  const { t } = useTranslation();

  return (
    <FormControl {...props}>
      <InputLabel shrink>{label ? t(label) : ''}</InputLabel>
      <Typography
        sx={{
          overflowY: 'scroll', // Make the content scrollable
          display: 'flex',
          alignItems: 'center',
          marginTop: 2,
          height: 40,
          '&::-webkit-scrollbar': {
            display: 'none', // Hide the scrollbar in webkit browsers
          },
        }}
        {...slotProps?.Typography}
      >
        {value || '—'}
      </Typography>
    </FormControl>
  );
};
