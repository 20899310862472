import type { TFunction } from 'react-i18next';

import type { TAddress, TOption } from '@/types/common';

export const capitalize = (str = '') =>
  `${str.substr(0, 1).toUpperCase()}${str.substr(1).toLowerCase()}`;

export const formatTimeLeft = (coolDown: number) => {
  const minutes = `${Math.floor(coolDown / 60)}`.padStart(2, '0');
  const seconds = `${Math.floor(coolDown % 60)}`.padStart(2, '0');

  return `${minutes}:${seconds}`;
};

export const highlightMatch = (value: string, query: string) => {
  if (!query) {
    return value;
  }

  const regex = new RegExp(query.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi');
  return value.replace(regex, (str) => `<strong>${str}</strong>`);
};

export const formatAddress = ({ addressLine1, city, state, zipCode }: TAddress) =>
  [addressLine1, city, [state, zipCode].filter(Boolean).join(' ')].filter(Boolean).join(', ');

export const optionToDisplay = <PValue extends string>(
  options: TOption<PValue>[],
  value: PValue | undefined,
  t: TFunction,
) => {
  const option = options.find((opt) => opt.value === value);

  return option ? t(option.label) : '';
};

export const toCdn = (path: string | null | undefined) => {
  const CDN_HOST = '';
  if (!path) {
    return '';
  }

  if (/https?:\/\//.test(path)) {
    return path;
  }

  return `${CDN_HOST}/${path}`;
};

export const toFullName = ({ firstName, lastName }: { firstName?: string; lastName?: string }) =>
  [firstName, lastName].filter(Boolean).join(' ');

export const summarize = (explicit: string[], all: unknown[], t: TFunction, moreKey: string) => {
  const rest = all.length - explicit.length;
  const base = explicit.join(', ');

  if (rest > 0) {
    return `${base}... ${t(moreKey, { value: rest })}`;
  }

  return base;
};
