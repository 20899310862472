import { typedStyles } from '@/utils/styleUtils';

export const styles = typedStyles({
  root: {
    paddingX: 3,
    width: 'min(80%, 884px)',
    height: '90%',
    paddingTop: 6,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  link: {
    display: 'block',
    marginBottom: 2,
    fontFamily: 'Metropolis',
    fontWeight: '500',
  },
});
