export const fromTemplate = <PItem>(length: number, makeItem: (index: number) => PItem) =>
  new Array(length).fill(null).map((v, index) => makeItem(index));

export const filterFalsy = <PItem>(item: PItem | false): item is PItem => !!item;

export const partition = <PItem>(items: PItem[], predicate: (item: PItem) => boolean) =>
  items.reduce<[PItem[], PItem[]]>(
    (acc, item) => {
      if (predicate(item)) {
        acc[0].push(item);
      } else {
        acc[1].push(item);
      }

      return acc;
    },
    [[], []],
  );
