import { SvgIcon, SvgIconProps } from '@mui/material';
import { useTheme } from '@mui/styles';

export const AlertIcon = ({ color = 'error', ...props }: SvgIconProps) => {
  const { palette } = useTheme();

  return (
    <SvgIcon viewBox="0 0 16 16" color={color} {...props}>
      <circle xmlns="http://www.w3.org/2000/svg" cx="8" cy="8" r="6.66667" fill="currentColor" />

      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M8 8.66671V5.33337"
        stroke={palette.custom.contrast[9]}
        strokeWidth="1.33333"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <rect
        xmlns="http://www.w3.org/2000/svg"
        x="7.33334"
        y="10"
        width="1.33333"
        height="1.33333"
        fill={palette.custom.contrast[9]}
      />
    </SvgIcon>
  );
};
