import dayjs, { Dayjs } from 'dayjs';

import { DATE_FORMAT, TIMESTAMP_FORMAT, TIME_FORMAT } from '@/config/time';

export const isInRange = (date: Dayjs, start: Dayjs | null, end: Dayjs | null) =>
  !!(start || end) && (!start || start <= date) && (!end || end >= date);

// PAssert = true - to have an asserted Dayjs value when passing a 100% valid literal
export const parseDate = <PAssert = false>(value: string) => {
  const date = dayjs(value);

  return (date.isValid() ? date : null) as PAssert extends true ? Dayjs : Dayjs | null;
};

export const parseTime = <PAssert = false>(time: string) =>
  parseDate<PAssert>(`2000-01-01T${time}`);

export const dateToDoubleDecker = (dateStr: string | undefined) => {
  if (!dateStr) {
    return { title: '' };
  }

  const date = dayjs(dateStr);

  return {
    title: date.format(DATE_FORMAT),
    details: date.format(TIME_FORMAT),
  };
};

export const getDate = (dateStr: string | undefined) => {
  if (!dateStr) {
    return '-';
  }

  const date = dayjs(dateStr);

  return date.format(DATE_FORMAT);
};

export const getTime = (dateStr: string | undefined) => {
  if (!dateStr) {
    return '-';
  }

  const date = dayjs(dateStr);

  return date.format('HH:mm:ss');
};

export const generateTimestamp = () => dayjs().format(TIMESTAMP_FORMAT);

export const getFormattedDate = (dateString: string): string => {
  const dateObject: Date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  const formattedDate: string = dateObject.toLocaleDateString('en-US', options);
  return formattedDate;
};

export const getExtractedDate = (date: Date): string => {
  const formattedDate = new Date(date);
  const year = formattedDate.getFullYear();
  const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
  const day = String(formattedDate.getDate()).padStart(2, '0');

  const issueDate = `${year}-${month}-${day}`;
  return issueDate;
};
