import { SvgIcon, SvgIconProps } from '@mui/material';

export const EyeSlashIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 22 20" {...props}>
    <path
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.46967 0.46967C1.76256 0.176777 2.23744 0.176777 2.53033 0.46967L5.35611 3.29545L9.40896 7.3483L13.6516 11.5909L17.7046 15.6439L20.5303 18.4697C20.8232 18.7626 20.8232 19.2374 20.5303 19.5303C20.2374 19.8232 19.7626 19.8232 19.4697 19.5303L17.0737 17.1343C15.4783 18.1062 13.4611 18.75 11 18.75C7.28446 18.75 4.5928 17.2834 2.83476 15.4577C1.10654 13.663 0.25 11.485 0.25 10C0.25 8.30974 1.36419 5.67946 3.68861 3.74927L1.46967 1.53033C1.17678 1.23744 1.17678 0.762563 1.46967 0.46967ZM4.75447 4.81513C2.65136 6.50541 1.75 8.79389 1.75 10C1.75 11.015 2.39346 12.837 3.91524 14.4173C5.4072 15.9666 7.71554 17.25 11 17.25C13.0186 17.25 14.6642 16.7655 15.9768 16.0374L13.0679 13.1286C12.4752 13.521 11.7639 13.75 10.9999 13.75C8.92884 13.75 7.24991 12.0711 7.24991 10C7.24991 9.23599 7.47892 8.52469 7.87133 7.93199L4.75447 4.81513ZM8.96907 9.02973C8.82845 9.32346 8.74991 9.65234 8.74991 10C8.74991 11.2426 9.75727 12.25 10.9999 12.25C11.3476 12.25 11.6764 12.1715 11.9702 12.0308L8.96907 9.02973ZM11 2.75C9.96514 2.75 9.03051 2.87735 8.19036 3.09792C8.03236 3.1394 7.87761 3.1842 7.72609 3.23211C7.33114 3.35697 6.90975 3.13803 6.78489 2.74309C6.66002 2.34814 6.87897 1.92675 7.27391 1.80189C7.44903 1.74652 7.62754 1.69485 7.80946 1.64709C8.77804 1.3928 9.8409 1.25 11 1.25C14.7155 1.25 17.4072 2.71662 19.1652 4.54227C20.8935 6.33695 21.75 8.51504 21.75 10C21.75 11.0899 21.2895 12.5321 20.3966 13.9082C20.2795 14.0887 20.1545 14.2688 20.0215 14.4476C19.7743 14.78 19.3044 14.849 18.9721 14.6018C18.6397 14.3546 18.5707 13.8847 18.8179 13.5524C18.9317 13.3994 19.0385 13.2456 19.1383 13.0918C19.9206 11.886 20.25 10.7242 20.25 10C20.25 8.98496 19.6065 7.16305 18.0848 5.58273C16.5928 4.03338 14.2845 2.75 11 2.75Z"
    />
  </SvgIcon>
);
