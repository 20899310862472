import { breakpoints } from './breakpoints';

const mobile = `@media (max-width: ${breakpoints.values.sm}px)`;
const tablet = `@media (max-width: ${breakpoints.values.tablet}px)`;
const laptop = `@media (max-width: ${breakpoints.values.laptop}px)`;

export const typography = {
  fontFamily: "'Metropolis', 'Helvetica', 'Arial', 'sans-serif'",
  h1: {
    fontSize: 40,
    fontWeight: 400,
    lineHeight: '48px',
  },
  h2: {
    letterSpacing: -0.5,
    fontSize: 64,
    [laptop]: {
      fontSize: 53,
    },
    [tablet]: {
      fontSize: 46,
    },
    [mobile]: {
      fontSize: 38,
    },
  },
  h3: {
    fontSize: 48,
    letterSpacing: 0,
    [laptop]: {
      fontSize: 44,
    },
    [tablet]: {
      fontSize: 41,
    },
    [mobile]: {
      fontSize: 32,
    },
  },
  h4: {
    fontSize: 28,
    fontWeight: 600,
    lineHeight: '36px',
    [tablet]: {
      fontSize: 29,
    },
    [mobile]: {
      fontSize: 25,
    },
  },
  h5: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '32px',
    [tablet]: {
      fontSize: 22,
    },
    [mobile]: {
      fontSize: 20,
    },
  },
  h6: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '28px',
    [tablet]: {
      fontSize: 20,
    },
    [mobile]: {
      fontSize: 18,
    },
  },
  subtitle1: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '24px',

    [tablet]: {
      fontSize: 16,
    },
  },
  subtitle2: {
    fontSize: 14,
    letterSpacing: 0.1,
    [tablet]: {
      fontSize: 12,
    },
  },
  body1: {
    fontSize: 16,
    lineHeight: '24px',
  },
  body2: {
    fontSize: 14,
    lineHeight: '24px',
  },
  button: {
    fontWeight: 500,
  },
  caption: {
    fontSize: 12,
    lineHeight: '16px',
  },
  overline: {
    fontSize: 10,
    letterSpacing: 1.5,
    [tablet]: {
      fontSize: 8,
    },
  },
};
