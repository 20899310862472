import { Box } from '@mui/material';
import { useFormikContext } from 'formik';

import { NewPasswordErrors } from '@/components/formik/error/NewPasswordErrors';
import { SimpleError } from '@/components/formik/error/SimpleError';
import type { TErrorProps } from '@/components/formik/error/types';
import { ReservedLines } from '@/components/reservedLines/ReservedLines';

import type { TNewPasswordRules } from '@/validation/common/newPassword';

import { TCreatePasswordForm } from './types';

export const CreatePasswordErrors = (props: TErrorProps) => {
  const { errors, submitCount } = useFormikContext<TCreatePasswordForm>();

  if (errors.newPassword) {
    return (
      <NewPasswordErrors
        error={errors.newPassword as unknown as TNewPasswordRules}
        errorsShown={submitCount > 0}
      />
    );
  }

  return (
    <Box paddingY={0.5}>
      <SimpleError {...props} />
      <ReservedLines />
      <ReservedLines />
    </Box>
  );
};
