import {
  Table as MuiTable,
  TableBody,
  TableHead,
  TableRow as MuiTableRow,
  Typography,
} from '@mui/material';

import { BodyTableRow } from './BodyTableRow';
import type { ArrayElementType, TTableProps, TVMBase } from './types';
import { SimpleWrapperCell } from '@/components/table/bodyCells/SimpleWrapperCell';

export const Table = <PVM extends TVMBase>({
  data,
  headCells,
  bodyCells,
  bodyCollapsableCells,
  onRowClick,
  textIfNoTickets,
  selectedTicket,
  collapsable,
  ...props
}: TTableProps<PVM>) => {
  const getCollapsableItems = (baseData: PVM['collapsableRows']) =>
    baseData?.map((row) => (
      <MuiTableRow key={row.id}>
        {bodyCollapsableCells?.(row as ArrayElementType<PVM['collapsableRows']>)}
      </MuiTableRow>
    ));

  return (
    <MuiTable {...props}>
      <TableHead>
        <MuiTableRow>{headCells()}</MuiTableRow>
      </TableHead>

      <TableBody>
        {data.map((vm) => (
          <BodyTableRow
            key={vm.id}
            onClick={() => onRowClick?.(vm)}
            hover={!!onRowClick}
            collapsable={collapsable}
            collapsableItems={getCollapsableItems(vm.collapsableRows)}
            sx={{
              backgroundColor: selectedTicket
                ? selectedTicket.id === vm.id
                  ? '#F5F6FA'
                  : 'inherit'
                : 'inherit',
            }}
          >
            {bodyCells(vm)}
          </BodyTableRow>
        ))}
        {data.length === 0 && textIfNoTickets && (
          <BodyTableRow key={'empty table'}>
            <SimpleWrapperCell key="plantId" colSpan={7} style={{ height: 'auto' }}>
              <Typography
                variant="body1"
                sx={{ fontWeight: 500, padding: 1, margin: 1, textAlign: 'center', width: '100%' }}
              >
                {textIfNoTickets}
              </Typography>
            </SimpleWrapperCell>
          </BodyTableRow>
        )}
      </TableBody>
    </MuiTable>
  );
};
