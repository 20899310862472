import * as Yup from 'yup';

import { requiredEmail, requiredString, newPassword } from './common';

export const enterEmailsSchema = Yup.object({
  email: requiredEmail,
  confirmEmail: requiredEmail.oneOf([Yup.ref('email'), ''], 'changeEmail.dontMatch'),
}).required();

export const confirmPasswordSchema = Yup.object({
  password: requiredString,
}).required();

export const changePasswordSchema = Yup.object({
  currentPassword: requiredString,
  newPassword,
  confirmPassword: requiredString.oneOf([Yup.ref('newPassword'), ''], 'forgotPassword.dontMatch'),
}).required();
