import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { Collapsable } from '../collapsable/Collapsable';
import { SearchInput } from '../searchInput/SearchInput';
import { styles } from './Faq.styles';
import { Tabs } from './Tabs';
import { getFaqData } from './utils';

export const Content = () => {
  const { t } = useTranslation();
  const tabs = getFaqData(t);
  const [searchValue, setSearchValue] = useState('');
  const [selectedTab, setSelecltedTab] = useState(tabs[0].key);
  const selectedTabInfo = tabs.find(({ key }) => key === selectedTab);

  const itemsToDisplay =
    selectedTabInfo?.items.filter(({ answer, question, answerKey = '' }) => {
      let answerValue = answer;

      if (typeof answer !== 'string') {
        answerValue = t(answerKey);
      }

      return `${question} ${answerValue}`.toLowerCase().includes(searchValue.toLowerCase());
    }) ?? [];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <Tabs tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelecltedTab} />
      <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} />
      <Box sx={styles.accordionWrapper}>
        {itemsToDisplay?.map(({ answer, question }) => (
          <Collapsable
            key={question}
            title={
              <Typography component="span">
                <Typography component="span" sx={{ marginRight: 2 }}>
                  Q:
                </Typography>
                {question}
              </Typography>
            }
          >
            <Typography
              sx={{ display: 'flex', paddingX: 3, fontWeight: 600, whiteSpace: 'pre-line' }}
            >
              <Typography component="span" sx={{ marginRight: 2, fontWeight: 600 }}>
                A:
              </Typography>
              {answer}
            </Typography>
          </Collapsable>
        ))}
      </Box>
    </Box>
  );
};
