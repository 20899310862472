import { createContext, useState, useContext, ChangeEvent } from 'react';

import { ThemeProvider as MuiThemeProvider } from '@mui/material';

import { noop } from '@altirllc/shared/utils/functionalUtils';

import { getThemeByName } from '@/themesConfig';

import { TProviderProps } from './types';

const initialTheme = localStorage?.getItem('theme') || 'light';
const ThemeContext = createContext<{
  isDarkMode: boolean;
  toggleTheme: (event: ChangeEvent<HTMLInputElement>) => void;
  forceTheme: (theme: 'light' | 'dark') => void;
}>({
  isDarkMode: initialTheme === 'dark',
  toggleTheme: noop,
  forceTheme: noop,
});

export const ThemeProvider = ({ children }: TProviderProps) => {
  const [themeType, setThemeType] = useState(initialTheme);
  const isDarkMode = themeType === 'dark';
  const theme = getThemeByName(themeType);

  const toggleTheme = (event: ChangeEvent<HTMLInputElement>) => {
    const nextThemeType = isDarkMode ? 'light' : 'dark';

    localStorage.setItem('theme', themeType);
    setThemeType(nextThemeType);
    event.stopPropagation();
  };

  const value = { isDarkMode, toggleTheme, forceTheme: setThemeType };

  return (
    <ThemeContext.Provider value={value}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
