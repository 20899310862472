import { typedStyles } from '@/utils/styleUtils';

import { colors } from '@/themesConfig/colors';

export const styles = typedStyles({
  inputWrapper: {
    backgroundColor: colors.lighterGrey,
    borderRadius: 6,
    marginTop: 3,
    marginBottom: 1,
    alignSelf: 'center',
    maxWidth: 'calc(100% - 144px)',
  },
});
