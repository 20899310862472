import { Trans } from 'react-i18next';

import { Button, Typography } from '@mui/material';

type TUploadFilePlaceholderProps = {
  select: () => void;
};

export const UploadFilePlaceholder = ({ select }: TUploadFilePlaceholderProps) => {
  return (
    <>
      <Typography variant="caption" textAlign="center" color="text.disabled">
        <Trans i18nKey="support.createTicket.dragOrUpload">
          <Button variant="text" onClick={select} disableRipple />
          <Typography variant="body2" component="span" color="text.primary" />
        </Trans>
      </Typography>
    </>
  );
};
