import axios from 'axios';

import type { TErrorPayload, TErrorResponse } from '@/types/common';

const statusConforms = (error: unknown, status: undefined | number) =>
  status === undefined || status === (error as TErrorResponse).response.status;

const codeConforms = (error: unknown, errorCode: undefined | string | RegExp) => {
  if (errorCode === undefined) {
    return true;
  }

  return typeof errorCode === 'string'
    ? errorCode === (error as TErrorResponse).response.data?.errorCode
    : errorCode.test((error as TErrorResponse).response.data?.errorCode);
};

export const isResponseError = <PPayload = TErrorPayload>(
  error: unknown,
  status?: number,
  errorCode?: string | RegExp,
): error is TErrorResponse<PPayload> =>
  axios.isAxiosError(error) &&
  !!error.response &&
  statusConforms(error, status) &&
  codeConforms(error, errorCode);
