import { useTranslation } from 'react-i18next';

import { Box, Button, Typography } from '@mui/material';

import type { TAction } from '@altirllc/shared/utils/types/common';

import type { TModalsContext } from '@/contexts/ModalsProvider/types';

import { GenericModal } from '@/components/modal/GenericModal';

type TConfirmCancelProps = TModalsContext & {
  message: string;
  onConfirm: TAction;
};

export const ConfirmCancel = ({ close, message, onConfirm }: TConfirmCancelProps) => {
  const { t } = useTranslation();

  return (
    <GenericModal
      sx={{
        width: 380,
        padding: 3,
        textAlign: 'center',
      }}
    >
      <Typography variant="h5">{t('confirmCancel.title')}</Typography>
      <Typography color="text.secondary">{t(message)}</Typography>

      <Box
        sx={{
          display: 'grid',
          grid: '1fr / auto-flow 1fr',
          marginTop: 3,
          gap: 2,
        }}
      >
        <Button variant="outlined" onClick={() => close('cancel.confirm')}>
          {t('confirmCancel.no')}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            close('cancel.confirm');
            onConfirm();
          }}
        >
          {t('confirmCancel.yes')}
        </Button>
      </Box>
    </GenericModal>
  );
};
