import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { Typography } from '@mui/material';

import type { TModalsContext } from '@/contexts/ModalsProvider/types';

import { GenericModal } from '@/components/modal/GenericModal';

import { GenericModalClose } from '../../modal/GenericModalClose';
import { styles } from './CreateTicket.styles';
import { CreateTicketForm } from './CreateTicketForm';
import { SubmitError } from '@/components/submitError/SubmitError';
import { createTicket, editTicket } from '@/services/support';
import { useSupportContext } from '@/contexts/SupportProvider';
import { AxiosError } from 'axios';
import { ErrorResponse } from '../types';
import dayjs from 'dayjs';
import { getExtractedDate } from '@/utils/dateUtils';

type CreateTicketProps = TModalsContext & {
  isEdit?: boolean;
  currentTicketDetails?: any;
  editTicketSubmitHandler?: (ticket: any) => void;
  submitTicketHandler?: (ticket: any) => void;
};

export const CreateTicket = ({
  open,
  close,
  isEdit = false,
  currentTicketDetails = {},
  editTicketSubmitHandler,
  submitTicketHandler,
}: CreateTicketProps) => {
  const { t } = useTranslation();
  const { appendTicketOnSuccess } = useSupportContext();
  const [filesList, setFilesList] = useState<File[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [errorOnCreateTicket, setErrorCreateTicket] = useState<string | null>(null);
  const defaultValues = {
    plantId: currentTicketDetails?.ticket?.cf?.cf_plant_id ?? '',
    productName: currentTicketDetails?.ticket?.product?.id ?? '',
    unitSerialNumber: currentTicketDetails?.ticket?.cf?.cf_product_sn ?? '',
    subject: currentTicketDetails?.ticket?.subject ?? '',
    issueDescription: currentTicketDetails?.ticket?.description ?? '',
    country: currentTicketDetails?.ticket?.customFields?.Country ?? '',
    language: currentTicketDetails?.ticket?.language ?? '',
    issueDate: dayjs(new Date(currentTicketDetails?.ticket?.cf?.cf_issue_date)) ?? null,
  };

  const closeModal = () => {
    close('support.createTicket');
  };

  const createTicketHandler = async (values: any, formikHelpers: any) => {
    const issueDate = getExtractedDate(values.issueDate);
    const jsonString = JSON.stringify({
      plantId: values.plantId ?? '',
      zohoProductId: values.productName ?? '',
      unitSn: values.unitSerialNumber ?? '',
      issueDate: issueDate,
      subject: values.subject ?? '',
      description: values.issueDescription ?? '',
      country: values.country ?? '',
      language: values.language ?? '',
    });
    try {
      const formdata = new FormData();
      const json = jsonString;
      const jsonBlob = new Blob([json], {
        type: 'application/json',
      });
      formdata.append('ticketRequest', jsonBlob);

      filesList.forEach((file, index) => {
        formdata.append('files', file);
      });
      if (isEdit) {
        const response = await editTicket({
          data: formdata,
          ticketId: currentTicketDetails?.ticket?.id,
        });
        editTicketSubmitHandler?.(response.data);
        closeModal();
      } else {
        const response = await createTicket(formdata);
        appendTicketOnSuccess(response.data);
        submitTicketHandler?.(response.data);
        closeModal();
      }
    } catch (error: unknown) {
      formikHelpers.setSubmitting(false);
      const axiosError = error as AxiosError<ErrorResponse>;
      if (axiosError.response) {
        // Response data from the server
        const errorFields = axiosError.response.data;
        const errors = errorFields?.errors ?? {};

        Object.entries(errors).forEach(([key, value]) => {
          switch (key) {
            case 'subject':
              formikHelpers.setFieldError('subject', value);
              break;
            case 'description':
              formikHelpers.setFieldError('issueDescription', errors['description']);
              break;
            case 'unitSn':
              formikHelpers.setFieldError('unitSerialNumber', errors['unitSerialNumber']);
              break;
            default:
              setError(t('support.createTicket.error'));
          }
        });
      }
    }
  };

  return (
    <GenericModal sx={styles.root}>
      <GenericModalClose onClick={closeModal} />
      <SubmitError
        error={error}
        dismiss={() => setError(null)}
        sx={{ top: 0, padding: 1, marginTop: 10, marginLeft: 0, marginRight: 35, maxWidth: '65%' }}
      />
      <Typography marginX={3} variant="h5" textAlign="center">
        {t(isEdit ? 'support.createTicket.editTicket' : 'support.createTicket.title')}
      </Typography>
      <CreateTicketForm
        handleSubmit={createTicketHandler}
        errorOnCreateTicket={errorOnCreateTicket}
        closeModal={closeModal}
        filesList={filesList}
        setFilesList={setFilesList}
        isEditTicketForm={isEdit}
        defaultValues={defaultValues}
      />
    </GenericModal>
  );
};
